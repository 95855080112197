import { createAction, props } from '@ngrx/store';
import { Workspace, WorkspaceEntity, WorkspaceFolder } from './workspaces.state';
import { Project } from '@contrail/entity-types';

export enum WorkspacesActionTypes {
  LOAD_WORKSPACES = '[Workspaces] Load Workspaces',
  LOAD_WORKSPACES_SUCCESS = '[Workspaces] Load Workspaces Success',
  LOAD_WORKSPACES_FAILURE = '[Workspaces] Load Workspaces Failure',

  CLEAR_WORKSPACES = '[Workspaces] Clear Workspaces',

  CREATE_WORKSPACE = '[Workspaces] Create Workspace',
  CREATE_WORKSPACE_SUCCESS = '[Workspaces] Create Workspace Success',
  CREATE_WORKSPACE_FAILURE = '[Workspaces] Create Workspace Failure',

  DELETE_WORKSPACE = '[Workspaces] Delete Workspace',
  DELETE_WORKSPACE_SUCCESS = '[Workspaces] Delete Workspace Success',
  DELETE_WORKSPACE_FAILURE = '[Workspaces] Delete Workspace Failure',

  UPDATE_WORKSPACE = '[Workspaces] Update Workspace',
  UPDATE_WORKSPACE_SUCCESS = '[Workspaces] Update Workspace Success',
  UPDATE_WORKSPACE_FAILURE = '[Workspaces] Update Workspace Failure',

  LOAD_CURRENT_WORKSPACE = '[Workspaces] Load Current Workspace',
  LOAD_CURRENT_WORKSPACE_SUCCESS = '[Workspaces] Load Current Workspace Success',
  LOAD_CURRENT_WORKSPACE_FAILURE = '[Workspaces] Load Current Workspace Failure',

  SET_CURRENT_WORKSPACE_FOLDER = '[Workspaces] Set Current Workspace Folder',

  SET_CURRENT_WORKSPACE_FOLDER_LOADING = '[Workspaces] Set Current Workspace Folder Loading',

  LOAD_WORKSPACE_FOLDER_ENTITIES = '[Workspaces] Load Workspace Folder Entities',
  LOAD_WORKSPACE_FOLDER_ENTITIES_SUCCESS = '[Workspaces] Load Workspace Folder Entities Success',
  LOAD_WORKSPACE_FOLDER_ENTITIES_FAILURE = '[Workspaces] Load Workspace Folder Entities Failure',

  CREATE_WORKSPACE_FOLDER = '[Workspaces] Create Workspace Folder',
  CREATE_WORKSPACE_FOLDER_SUCCESS = '[Workspaces] Create Workspace Success Folder',
  CREATE_WORKSPACE_FOLDER_FAILURE = '[Workspaces] Create Workspace Failure Folder',

  DELETE_WORKSPACE_FOLDER = '[Workspaces] Delete Workspace Folder',
  DELETE_WORKSPACE_FOLDER_SUCCESS = '[Workspaces] Delete Workspace Success Folder',
  DELETE_WORKSPACE_FOLDER_FAILURE = '[Workspaces] Delete Workspace Failure Folder',

  MOVE_FOLDER_ENTITIES = '[Workspaces] Move Folder Entities Folder',
  MOVE_FOLDER_ENTITIES_SUCCESS = '[Workspaces] Move Folder Entities Success Folder',
  MOVE_FOLDER_ENTITIES_FAILURE = '[Workspaces] Move Folder Entities Failure Folder',

  REMOVE_FOLDER_ENTITIES = '[Workspaces] Remove Folder Entities Folder',

  SET_WORKSPACE_FOLDER_STRUCTURE = '[Workspaces] Set Workspace Folder Structure',

  LOAD_CURRENT_WORKSPACE_PROJECT = '[Workspaces] Load Current Workspace Project',
  LOAD_CURRENT_WORKSPACE_PROJECT_SUCCESS = '[Workspaces] Load Current Workspace Project Success',
}

////////////////////////////////////////////////////////////////////////////////
export const clearWorkspaces = createAction(WorkspacesActionTypes.CLEAR_WORKSPACES);
export const loadWorkspaces = createAction(WorkspacesActionTypes.LOAD_WORKSPACES);

export const loadWorkspacesSuccess = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadWorkspacesFailure = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACES_FAILURE,
  props<{ error: any }>(),
);

export const createWorkspace = createAction(WorkspacesActionTypes.CREATE_WORKSPACE, props<{ workspace: Workspace }>());

export const createWorkspaceSuccess = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const createWorkspaceFailure = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const updateWorkspace = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE,
  props<{ id: string; changes: Workspace }>(),
);

export const updateWorkspaceSuccess = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE_SUCCESS,
  props<{ id: string; changes: Workspace }>(),
);

export const updateWorkspaceFailure = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const deleteWorkspace = createAction(WorkspacesActionTypes.DELETE_WORKSPACE, props<{ workspace: Workspace }>());

export const deleteWorkspaceSuccess = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const deleteWorkspaceFailure = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentWorkspace = createAction(WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE, props<{ id: string }>());

export const loadCurrentWorkspaceSuccess = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const loadCurrentWorkspaceFailure = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const setCurrentWorkspaceFolder = createAction(
  WorkspacesActionTypes.SET_CURRENT_WORKSPACE_FOLDER,
  props<{ workspace: Workspace }>(),
);

export const setCurrentWorkspaceFolderLoading = createAction(
  WorkspacesActionTypes.SET_CURRENT_WORKSPACE_FOLDER_LOADING,
  props<{ loading: boolean }>(),
);

export const loadWorkspaceFolderEntities = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES,
  props<{ workspaceId: string }>(),
);

export const loadWorkspaceFolderEntitiesSuccess = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES_SUCCESS,
  props<{ workspaceEntities: Array<WorkspaceEntity> }>(),
);

export const loadWorkspaceFolderEntitiesFailure = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES_FAILURE,
  props<{ error: any }>(),
);

export const createWorkspaceFolder = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FOLDER,
  props<{ workspace: Workspace }>(),
);

export const createWorkspaceFolderSuccess = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FOLDER_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const createWorkspaceFolderFailure = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FOLDER_FAILURE,
  props<{ error: any }>(),
);

export const deleteWorkspaceFolder = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FOLDER,
  props<{ workspaceId: string }>(),
);

export const deleteWorkspaceFolderSuccess = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FOLDER_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const deleteWorkspaceFolderFailure = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FOLDER_FAILURE,
  props<{ error: any }>(),
);

export const moveFolderEntities = createAction(
  WorkspacesActionTypes.MOVE_FOLDER_ENTITIES,
  props<{ entityReferences: Array<string>; targetWorkspaceId: string }>(),
);

export const moveFolderEntitiesSuccess = createAction(
  WorkspacesActionTypes.MOVE_FOLDER_ENTITIES_SUCCESS,
  props<{ workspaceEntityIds: Array<string> }>(),
);

export const moveFolderEntitiesFailure = createAction(
  WorkspacesActionTypes.MOVE_FOLDER_ENTITIES_FAILURE,
  props<{ error: any }>(),
);

export const setWorkspaceFolderStructure = createAction(
  WorkspacesActionTypes.SET_WORKSPACE_FOLDER_STRUCTURE,
  props<{ workspaceFolderStructure: Array<WorkspaceFolder> }>(),
);

/** Given a list of entity references, will remove
 * from the local store (not the API). Useful post delete
 * of a document, etc.
 */
export const removeFolderEntities = createAction(
  WorkspacesActionTypes.REMOVE_FOLDER_ENTITIES,
  props<{ entityReferences: Array<any> }>(),
);

export const loadCurrentWorkspaceProject = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_PROJECT,
  props<{ id: string }>(),
);

export const loadCurrentWorkspaceProjectSuccess = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_PROJECT_SUCCESS,
  props<{ project: Project }>(),
);
