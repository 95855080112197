import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { PropertyType } from '@contrail/types';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { DataCellEditBaseComponent } from './data-cell-edit-base-component';
import { CollectionDataEntity } from 'src/app/collection-manager/collection-manager.service';

@Component({
  selector: 'app-data-cell-edit',
  templateUrl: './data-cell-edit.component.html',
  styleUrls: ['./data-cell-edit.component.scss'],
})
export class DataCellEditComponent implements OnInit, OnChanges {
  @Input() property: ViewPropertyConfiguration;
  @Input() value: any;
  @Input() display: any;
  @Input() align: any;
  @Input() data: CollectionDataEntity;
  @Input() accessLevel = 'EDIT';
  @Input() valueCleared = false;
  @Output() valueChange = new EventEmitter<{ value }>();
  @Output() endEditChange = new EventEmitter();
  @Output() focusOnCell = new EventEmitter<any>();

  @ViewChildren('editCell') editComponentQuery: QueryList<any>;
  private editComponent: DataCellEditBaseComponent;
  private startingValue;
  public propertyType: PropertyType;

  constructor() {}

  ngOnInit(): void {}
  ngOnChanges() {
    this.propertyType = this.property.propertyDefinition.propertyType;
    this.startingValue = this.value;
  }
  ngAfterViewInit(): void {
    console.log('DataCellEditComponent: edit cell query: ', this.editComponentQuery);
    if (this.editComponentQuery.length) {
      this.editComponent = this.editComponentQuery.toArray()[0];
    }
  }

  completeEdit() {
    console.log('DataCellEditComponent: complete edit: ', this.editComponent);
    if (this.editComponent) {
      this.editComponent.completeEdit();
    }
  }

  handleChange($event = null) {
    if ($event) {
      this.value = $event.value;
    }
    console.log('#### DataCellEditComponent: handleChange: ', $event, this.startingValue, this.value);
    if (this.startingValue !== this.value) {
      this.valueChange.emit({ value: this.value });
    }
  }

  handleEndEdit() {
    this.endEditChange.emit();
  }

  handleFocusOnCell(htmlElement) {
    this.focusOnCell.emit(htmlElement);
  }

  resetValue() {
    this.value = this.startingValue;
  }

  get isItemFamilyOrOptionProperty() {
    return Boolean(
      ['itemFamily', 'itemOption'].includes(this.property.slug) &&
        this.propertyType === 'object_reference' &&
        this.property?.propertyDefinition?.referencedTypeRootSlug === 'item',
    );
  }
}
