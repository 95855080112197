import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { Injectable } from '@angular/core';
import { PlaceholderItemCreationService } from '../placeholders/placeholder-item-creation-service';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { PlansSelectors, RootStoreState } from 'src/app/root-store';
import { LiveDataEvent } from '@common/web-socket/session-message';
import { groupBy, get } from 'lodash';
import { OrgConfig } from 'src/app/common/auth/auth.service';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { take, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { PropertyType, PropertyLevel, EntityFormulaProcessor } from '@contrail/types';
import { CollectionDataUtil } from '@contrail/collection-data';
import { Item, Project, ProjectItem } from '@contrail/entity-types';
import { PlanTargetAssortment } from 'src/app/plans/plans-store/plans.state';
import { Types } from '@contrail/sdk';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

@Injectable({
  providedIn: 'root',
})
export class ProcessLiveUpdatesService {
  private typeMap;
  constructor(
    private placeholderItemCreationService: PlaceholderItemCreationService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => (this.typeMap = typeMap));
  }

  public async processLiveUpdates(entities: LiveDataEvent[]) {
    // get the current state
    const state = await firstValueFrom(this.store.select((state) => state));
    const orgConfig = this.getOrgConfig();

    const hasItemUpdates = entities.some((entity) => entity.entityType === 'item');
    const hasProjectItemUpdates = entities.some((entity) => entity.entityType === 'project-item');

    console.log('hasItemUpdates', hasItemUpdates);
    console.log('hasProjectItemUpdates', hasProjectItemUpdates);

    // get the current placeholders
    const placeholders = Object.values(state.collectionManager.collectionElements.entities);

    let placeHolderUpdates = {};

    // get all the family and option ids, and map from id to placeholders
    if (hasItemUpdates) {
      const planPlaceholdersByItemFamilyId = groupBy(placeholders, 'itemFamilyId');
      const planPlaceholdersByItemOptionId = groupBy(placeholders, 'itemOptionId');

      placeHolderUpdates = await this.processItemUpdatesForLiveUpdates(
        entities.filter((x) => x.entityType === 'item'),
        planPlaceholdersByItemFamilyId,
        planPlaceholdersByItemOptionId,
        placeHolderUpdates,
        orgConfig,
      );
    }

    if (hasProjectItemUpdates) {
      const planPlaceholdersByFamilyProjectItemId = groupBy(placeholders, (placeholder) =>
        get(placeholder, 'itemFamily.projectItem.id'),
      );
      const planPlaceholdersByOptionProjectItemId = groupBy(placeholders, (placeholder) =>
        get(placeholder, 'itemOption.projectItem.id'),
      );

      placeHolderUpdates = await this.processProjectItemUpdatesForLiveUpdates(
        entities.filter((x) => x.entityType === 'project-item'),
        planPlaceholdersByFamilyProjectItemId,
        planPlaceholdersByOptionProjectItemId,
        placeHolderUpdates,
      );
    }

    const updates: any[] = Object.values(placeHolderUpdates);
    const batchUpdate = updates.map((update) => {
      return { id: update.id, changes: update };
    });
    this.store.dispatch(
      CollectionManagerActions.batchApplyCollectionEntityChanges({
        changes: batchUpdate,
        broadcast: false,
        skipErrorValidation: true,
      }),
    );
  }

  public async processItemUpdatesForLiveUpdates(
    entities,
    planPlaceholdersByItemFamilyId,
    planPlaceholdersByItemOptionId,
    placeHolderUpdates,
    orgConfig: OrgConfig,
  ) {
    const itemUpdates = entities.map((entity) => entity.data);
    for (const itemUpdate of itemUpdates) {
      if (!itemUpdate) {
        continue;
      }

      const familyPlaceholders = planPlaceholdersByItemFamilyId[itemUpdate.id] || [];
      const optionPlaceholders = planPlaceholdersByItemOptionId[itemUpdate.id] || [];

      for (const placeholder of familyPlaceholders) {
        if (placeholder) {
          const placeholderChanges = placeHolderUpdates[placeholder.id] || {};

          let itemReadOnly = placeholder.itemFamily;
          if (placeholderChanges?.itemFamily) {
            itemReadOnly = placeholderChanges.itemFamily;
          }

          const item = { ...itemReadOnly };
          const itemUpdateCopy = { ...itemUpdate };
          this.handleObjectReferenceDrops('item', PropertyLevel.FAMILY, itemUpdate, itemUpdateCopy);

          Object.assign(item, itemUpdateCopy);

          const formulaContext = {
            previousObj: itemReadOnly,
          };
          await this.applyFormulaToItem(item, formulaContext);

          item.projectItem = placeholder.itemFamily.projectItem;
          const urlsToSet = CollectionDataUtil.getUrlsToSetForThumbnail(placeholder.itemOption, item, orgConfig);
          const changes = {
            id: placeholder.id,
            itemFamilyId: item.id,
            itemFamily: item,
            thumbnail: urlsToSet.thumbnail,
            thumbnailPreview: urlsToSet.thumbnailPreview,
          };

          this.placeholderItemCreationService.setValuesOnPlaceholder(
            'item',
            item,
            PropertyLevel.FAMILY,
            changes,
            placeholder,
          );
          const previousChanges = placeHolderUpdates[placeholder.id] || {};
          placeHolderUpdates[placeholder.id] = { ...previousChanges, ...changes };
        }
      }

      for (const placeholder of optionPlaceholders) {
        if (placeholder) {
          const placeholderChanges = placeHolderUpdates[placeholder.id] || {};

          let itemReadOnly = placeholder.itemOption;
          if (placeholderChanges?.itemOption) {
            itemReadOnly = placeholderChanges.itemOption;
          }

          const item = { ...itemReadOnly };
          const itemUpdateCopy = { ...itemUpdate };
          this.handleObjectReferenceDrops('item', PropertyLevel.OPTION, itemUpdate, itemUpdateCopy);

          Object.assign(item, itemUpdateCopy);

          const formulaContext = {
            previousObj: itemReadOnly,
          };
          await this.applyFormulaToItem(item, formulaContext);

          item.projectItem = placeholder.itemOption.projectItem;
          const urlsToSet = CollectionDataUtil.getUrlsToSetForThumbnail(item, placeholder.itemFamily, orgConfig);
          const changes = {
            id: placeholder.id,
            itemOptionId: item.id,
            itemOption: item,
            thumbnail: urlsToSet.thumbnail,
            thumbnailPreview: urlsToSet.thumbnailPreview,
          };

          this.placeholderItemCreationService.setValuesOnPlaceholder(
            'item',
            item,
            PropertyLevel.OPTION,
            changes,
            placeholder,
          );
          const previousChanges = placeHolderUpdates[placeholder.id] || {};
          placeHolderUpdates[placeholder.id] = { ...previousChanges, ...changes };
        }
      }
    }

    return placeHolderUpdates;
  }

  public async processProjectItemUpdatesForLiveUpdates(
    entities,
    planPlaceholdersByFamilyProjectItemId,
    planPlaceholdersByOptionProjectItemId,
    placeHolderUpdates,
  ) {
    const projectItemUpdates = entities.map((entity) => entity.data);
    const assortment = this.getAssortment();
    const project = this.getProject();

    for (const projectItemUpdate of projectItemUpdates) {
      if (!projectItemUpdate) {
        continue;
      }

      const familyPlaceholders = planPlaceholdersByFamilyProjectItemId[projectItemUpdate.id] || [];
      const optionPlaceholders = planPlaceholdersByOptionProjectItemId[projectItemUpdate.id] || [];

      for (const placeholder of familyPlaceholders) {
        if (placeholder) {
          const placeholderChanges = placeHolderUpdates[placeholder.id] || {};

          // try to use the new family item if it exists
          let itemReadOnly = placeholder.itemFamily;
          if (placeholderChanges?.itemFamily) {
            itemReadOnly = placeholderChanges.itemFamily;
          }

          const item = { ...itemReadOnly };

          const projectItemReadOnly = itemReadOnly.projectItem;
          const projectItem = { ...projectItemReadOnly };
          const projectItemUpdateCopy = { ...projectItemUpdate };
          this.handleObjectReferenceDrops(
            'project-item',
            PropertyLevel.FAMILY,
            projectItemUpdate,
            projectItemUpdateCopy,
          );

          Object.assign(projectItem, projectItemUpdate);

          const formulaContext = {
            assortment,
            previousObj: projectItemReadOnly,
            project,
          };

          await this.applyFormulaToProjectItem(projectItem, item, formulaContext);

          item.projectItem = projectItem;

          const changes = {
            id: placeholder.id,
            itemFamily: item,
          };

          this.placeholderItemCreationService.setValuesOnPlaceholder(
            'project-item',
            projectItem,
            PropertyLevel.FAMILY,
            changes,
            placeholder,
          );
          placeHolderUpdates[placeholder.id] = { ...placeholderChanges, ...changes };
        }
      }

      for (const placeholder of optionPlaceholders) {
        if (placeholder) {
          const placeholderChanges = placeHolderUpdates[placeholder.id] || {};

          // try to use the new option item if it exists
          let itemReadOnly = placeholder.itemOption;
          if (placeholderChanges?.itemOption) {
            itemReadOnly = placeholderChanges.itemOption;
          }

          const item = { ...itemReadOnly };

          const projectItemReadOnly = itemReadOnly.projectItem;
          const projectItem = { ...projectItemReadOnly };
          const projectItemUpdateCopy = { ...projectItemUpdate };
          this.handleObjectReferenceDrops(
            'project-item',
            PropertyLevel.OPTION,
            projectItemUpdate,
            projectItemUpdateCopy,
          );

          Object.assign(projectItem, projectItemUpdate);

          const formulaContext = {
            assortment,
            previousObj: projectItemReadOnly,
            project,
          };

          await this.applyFormulaToProjectItem(projectItem, item, formulaContext);

          item.projectItem = projectItem;

          const changes = {
            id: placeholder.id,
            itemOption: item,
          };

          this.placeholderItemCreationService.setValuesOnPlaceholder(
            'project-item',
            projectItem,
            PropertyLevel.OPTION,
            changes,
            placeholder,
          );
          placeHolderUpdates[placeholder.id] = { ...placeholderChanges, ...changes };
        }
      }
    }

    return placeHolderUpdates;
  }

  private getOrgConfig() {
    let org;
    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((currentOrg) => {
          org = currentOrg;
        }),
      )
      .subscribe();
    return org.orgConfig;
  }

  private handleObjectReferenceDrops(type, level, update, changes) {
    const typeProperties = this.placeholderItemCreationService.getRelevantProperties(type, level);
    const typePropertiesBySlug = _.keyBy(typeProperties, 'slug');
    for (const updateKey of Object.keys(update)) {
      if (updateKey.endsWith('Id')) {
        // Remove the "Id" ending to get the actual property key
        const baseKey = updateKey.slice(0, -2);
        const property = typePropertiesBySlug[baseKey];

        // Proceed with the existing logic if the property exists and matches the condition
        if (
          property &&
          [PropertyType.ObjectReference, PropertyType.TypeReference, PropertyType.UserList].includes(
            property.propertyType,
          ) &&
          !update[updateKey]
        ) {
          changes[property.slug] = null;
        }
      }
    }
  }

  private async applyFormulaToItem(item: Item, formulaContext: { previousObj: Item }): Promise<void> {
    const itemType = await new Types().getType({ id: item.typeId });
    await EntityFormulaProcessor.processAndSetFormulaResultsOnEntity(item, {
      type: itemType,
      formulaContext,
      enableDebugLogs: false,
    });
  }

  private async applyFormulaToProjectItem(
    projectItem: ProjectItem,
    item: Item,
    formulaContext: {
      assortment: PlanTargetAssortment;
      previousObj: ProjectItem;
      project: Project;
    },
  ): Promise<void> {
    const projectItemType = this.typeMap['project-item'];

    projectItem.item = item;
    await EntityFormulaProcessor.processAndSetFormulaResultsOnEntity(projectItem, {
      type: projectItemType,
      formulaContext,
      enableDebugLogs: false,
    });

    delete projectItem.item;
  }

  private getAssortment(): PlanTargetAssortment {
    let plan;
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((currentPlan) => {
          plan = currentPlan;
        }),
      )
      .subscribe();
    return plan?.targetAssortment;
  }

  private getProject(): Project {
    let project;
    this.store
      .select(WorkspacesSelectors.currentProject)
      .pipe(
        take(1),
        tap((currentProject) => {
          project = currentProject;
        }),
      )
      .subscribe();

    return project;
  }
}
