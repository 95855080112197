import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { nanoid } from 'nanoid';
import { chunk } from 'lodash';
import pLimit from 'p-limit';
import { ProjectItem } from '@common/projects/project-item.service';
import { Entities, Types } from '@contrail/sdk';
import { FormulaFunctionProcessor, PropertyType, TypeProperty, PropertyLevel } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Item } from '@common/items/item';
import { BadRequestError } from '@common/errors/bad-request-error';
import { RootStoreState, UserSessionSelectors } from '../../root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { PromoteOptionModalComponent } from './promote-option-modal/promote-option-modal.component';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { PlaceholderItemAssignmentService } from './placeholder-item-assignment-service';
import { CollectionElementActionValidator } from '../collection-element-validator/collection-element-action-validator';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { ProjectItemUpdateService } from '../project-items/project-item-update-service';

const limit = pLimit(3);

@Injectable({
  providedIn: 'root',
})
export class PlaceholderItemCreationService {
  private typeMap;
  private sessionId;
  constructor(
    private store: Store<RootStoreState.State>,
    private projectItemUpdateService: ProjectItemUpdateService,
    private phItemAssignmentService: PlaceholderItemAssignmentService,
    private collectionElementActionValidator: CollectionElementActionValidator,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private webSocketService: WebSocketService,
  ) {
    this.store.select(CollectionManagerSelectors.typeDefinitions).subscribe((typeMap) => (this.typeMap = typeMap));
    this.store.select(UserSessionSelectors.currentSessionId).subscribe((sessionId) => {
      if (sessionId) {
        this.sessionId = sessionId;
      }
    });
  }

  public async promotePlaceholdersToItems(placeholders: Array<any>) {
    // Filter out any placeholders that already have an item option assigned.
    placeholders = placeholders.filter((ph) => !ph.itemOptionId);
    placeholders = ObjectUtil.cloneDeep(placeholders);
    if (placeholders.length === 0) {
      return;
    }

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));

    try {
      const { createdFamilyItems, familyPlaceholdersToUpdate } =
        await this.assignFamilyItemsToPlaceholders(placeholders);
      const optionPlaceholdersToUpdate = await this.assignOptionItemsToPlaceholders(placeholders, createdFamilyItems);
      const mergedPlaceholdersToUpdate = this.mergePlaceholdersToUpdate(
        familyPlaceholdersToUpdate,
        optionPlaceholdersToUpdate,
      );
      this.updatePlanPlaceholders(mergedPlaceholdersToUpdate);
    } catch (error) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
      throw error;
    }

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  private async assignFamilyItemsToPlaceholders(
    placeholders: Array<any>,
  ): Promise<{ createdFamilyItems: Item[]; familyPlaceholdersToUpdate: any[] }> {
    // Dertermine which placeholders need a new family.
    const newFamilyPhs = placeholders.filter((ph) => !ph.itemFamilyId && ph.name);
    if (newFamilyPhs.length === 0) {
      return { createdFamilyItems: [], familyPlaceholdersToUpdate: [] };
    }

    const rootItemType = this.typeMap['item'];

    const familyMap = {};
    for (const placeholder of newFamilyPhs) {
      familyMap[placeholder.name] = familyMap[placeholder.name] || [];
      const itemFamilyId = familyMap[placeholder.name][0]?.itemFamilyId || nanoid(16);
      placeholder.itemFamilyId = itemFamilyId;
      familyMap[placeholder.name].push(placeholder);
    }

    const itemsToCreate = await this.getFamilyItemsToCreateFromPlaceholders(familyMap);
    const { items: createdFamilyItems, projectItems: upsertedProjectItems } =
      await this.batchCreateItemsAndProjectItemsForPlaceholders(newFamilyPhs, itemsToCreate, PropertyLevel.FAMILY);

    const planPlaceholdersToUpdate = [];
    for (const familyName of Object.keys(familyMap)) {
      const relatedPhs = familyMap[familyName];
      if (relatedPhs.length > 0) {
        const itemFamilyId = relatedPhs[0].itemFamilyId;
        const newFamily = createdFamilyItems.find((item) => item && item.id === itemFamilyId);
        if (!newFamily) {
          continue;
        }

        for (const ph of relatedPhs) {
          // ASSIGN & UPDATE ALL PLACEHOLDERS
          const changes: any = {
            itemFamilyId: newFamily.id,
            itemFamily: newFamily,
          };

          if (!ph.itemTypeId) {
            changes.itemType = rootItemType;
            changes.itemTypeId = rootItemType.id;
          }

          this.setValuesOnPlaceholder('item', newFamily, PropertyLevel.FAMILY, changes, ph);

          const newProjectItem = upsertedProjectItems.find(
            (projectItem) => projectItem && projectItem.itemId === newFamily.id,
          );
          if (newProjectItem) {
            this.setValuesOnPlaceholder('project-item', newProjectItem, PropertyLevel.FAMILY, changes, ph);
            newFamily.projectItem = newProjectItem;
          }

          ph.itemFamily = newFamily;
          ph.itemFamilyId = newFamily.id;
          planPlaceholdersToUpdate.push({ id: ph.id, changes });
        }
      }
    }

    return { createdFamilyItems, familyPlaceholdersToUpdate: planPlaceholdersToUpdate };
  }

  private async assignOptionItemsToPlaceholders(placeholders: Array<any>, createdFamilyItems: Item[]) {
    const itemOptionPlaceholders = placeholders.filter((ph) => !ph.itemOptionId && ph.optionName && ph.itemFamily?.id);
    if (itemOptionPlaceholders.length === 0) {
      return [];
    }

    await this.deriveOptionNameOfPlaceholders(itemOptionPlaceholders);

    const placeholdersWithExistingOptions = await this.getPlaceholdersWithExistingOptionNames(
      itemOptionPlaceholders,
      createdFamilyItems,
    );
    const placeholdersWithExistingOptionsIds = placeholdersWithExistingOptions.map((ph) => ph.id);
    const validPlaceholders = itemOptionPlaceholders
      .filter((ph) => !placeholdersWithExistingOptionsIds.includes(ph.id))
      .map((ph) => {
        return { ...ph, itemOptionId: nanoid(16) };
      });

    const itemsToCreate = this.getOptionItemsToCreateFromPlaceholders(validPlaceholders);
    const { items: createdOptionItems, projectItems: upsertedProjectItems } =
      await this.batchCreateItemsAndProjectItemsForPlaceholders(validPlaceholders, itemsToCreate, PropertyLevel.OPTION);

    const planPlaceholdersToUpdate = [];
    for (const ph of validPlaceholders) {
      if (placeholdersWithExistingOptionsIds.includes(ph.id)) continue;

      const newOption = createdOptionItems.find(
        (item) => item.id === ph.itemOptionId && item.itemFamilyId === ph.itemFamilyId,
      );
      if (!newOption) {
        continue;
      }

      const changes = {
        itemOptionId: newOption.id,
        itemOption: newOption,
        thumbnail: null,
      };
      this.setValuesOnPlaceholder('item', newOption, PropertyLevel.OPTION, changes, ph);

      const newProjectItem = upsertedProjectItems.find(
        (projectItem) => projectItem && projectItem.itemId === newOption.id,
      );
      if (newProjectItem) {
        this.setValuesOnPlaceholder('project-item', newProjectItem, PropertyLevel.OPTION, changes, ph);
        newOption.projectItem = newProjectItem;
      }

      planPlaceholdersToUpdate.push({ id: ph.id, changes });
    }

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));

    if (placeholdersWithExistingOptions.length) {
      const dialogRef = this.matDialog.open(PromoteOptionModalComponent, {
        width: '650px',
        minHeight: '300px',
        data: placeholdersWithExistingOptions,
      });

      dialogRef.afterClosed().subscribe(async (phCandidates) => {
        if (phCandidates.length === 0) {
          return;
        }
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
        for (let phCandidate of phCandidates) {
          const placeholder = placeholdersWithExistingOptions.find(
            (phWithExistingOption) => phWithExistingOption.id === phCandidate.placeholderId,
          );
          await this.phItemAssignmentService.setItemOptionOnPlaceholder(placeholder, phCandidate.optionId);
        }
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
      });
    }

    return planPlaceholdersToUpdate;
  }

  private async getPlaceholdersWithExistingOptionNames(placeholders: any[], createdFamilyItems: Item[]) {
    const familyIdMap = this.mapByFamilyId(placeholders);
    const newlyCreatedFamilyIds = createdFamilyItems.map((item) => item.id);
    const existingFamilyIds = Object.keys(familyIdMap).filter(
      (itemFamilyId) => !newlyCreatedFamilyIds.includes(itemFamilyId),
    );
    const existingOptionsResults = await Promise.all(
      existingFamilyIds.map((itemFamilyId) => new Entities().get({ entityName: 'item', criteria: { itemFamilyId } })),
    );

    const existingOptions = [].concat(...existingOptionsResults);
    const placeholdersWithDuplicateOptions = [];
    for (const itemFamilyId of Object.keys(familyIdMap)) {
      const existingOptionsForFamily = existingOptions.filter((option) => option.itemFamilyId === itemFamilyId);
      const existingOptionNames = existingOptionsForFamily
        .filter((optionName) => optionName)
        .map((option) => option.optionName);
      const newPlaceholderOptions = familyIdMap[itemFamilyId];
      const optionNameMap = {};
      for (const placeholder of newPlaceholderOptions) {
        if (!placeholder.optionName) continue;

        if (optionNameMap[placeholder.optionName] || existingOptionNames.includes(placeholder.optionName)) {
          placeholdersWithDuplicateOptions.push(placeholder);
        } else {
          optionNameMap[placeholder.optionName] = true;
        }
      }
    }

    return placeholdersWithDuplicateOptions;
  }

  private mergePlaceholdersToUpdate(
    familyPlaceholdersToUpdate: { id: string; changes: any }[],
    optionPlaceholdersToUpdate: { id: string; changes: any }[],
  ): { id: string; changes: any }[] {
    const mapOfPhChangesById = new Map();

    for (const familyPhToUpdate of familyPlaceholdersToUpdate) {
      const existingChanges = mapOfPhChangesById.get(familyPhToUpdate.id) || {};
      mapOfPhChangesById.set(familyPhToUpdate.id, { ...existingChanges, ...familyPhToUpdate.changes });
    }

    for (const optionPhToUpdate of optionPlaceholdersToUpdate) {
      const existingChanges = mapOfPhChangesById.get(optionPhToUpdate.id) || {};
      mapOfPhChangesById.set(optionPhToUpdate.id, { ...existingChanges, ...optionPhToUpdate.changes });
    }

    const mergedPlaceholdersToUpdate = Array.from(mapOfPhChangesById.entries()).map(([id, changes]) => ({
      id,
      changes,
    }));
    return mergedPlaceholdersToUpdate;
  }

  private mapByFamilyId(placeholders) {
    const map = {};
    for (const ph of placeholders) {
      map[ph.itemFamilyId] = map[ph.itemFamilyId] || [];
      map[ph.itemFamilyId].push(ph);
    }
    return map;
  }

  /** Creates an item from a single placeholder.  This function is used from the
   * object reference modal, with is specific to either a family or option.
   */
  public async createItem(ph: any, propertySlug: string, itemName: string) {
    const placeholder = ObjectUtil.cloneDeep(ph);
    console.log('createItem: ', placeholder, propertySlug, itemName);
    if (!propertySlug) {
      return;
    }
    if (propertySlug === 'itemFamily') {
      return this.createAndAssignFamily(placeholder, itemName);
    } else if (propertySlug === 'itemOption') {
      return this.createAndAssignOption(placeholder, itemName);
    }
  }

  /** Creates a new option and assigns it to a placeholder
   */
  async createAndAssignOption(placeholder: any, itemName: string) {
    const item = await this.createOptionFromPlaceholder(placeholder, itemName);
    if (!item) return;

    const changes = {
      itemOptionId: item.id,
      itemOption: item,
      optionName: item.optionName,
    };
    this.setValuesOnPlaceholder('item', item, PropertyLevel.OPTION, changes, placeholder);
    await this.setProjectItemValues(placeholder, item, PropertyLevel.OPTION, changes);
    this.store.dispatch(CollectionManagerActions.updateCollectionDataEntity({ id: placeholder.id, changes }));
    return item;
  }
  async createOptionFromPlaceholder(placeholder, itemName) {
    const itemOption = this.buildItemOptionFromPlaceholder(placeholder, itemName);
    if (!itemOption) return;

    this.webSocketService.sendMessage({
      sessionId: this.sessionId,
      action: 'ADD_LIVE_DATA_STREAM_TOPIC',
      topicsToSubscribe: [`item:${itemOption.specifiedId}`],
    });

    const item = await new Entities().create({
      entityName: 'item',
      object: itemOption,
    });
    return item;
  }

  private buildItemOptionFromPlaceholder(placeholder, itemName) {
    const itemScopedValues = this.getCreationValues(placeholder, 'item', PropertyLevel.OPTION);
    delete itemScopedValues.name; // TO DO: NEED TO HANDLE SCOPING OF ITEM VALUES!!!!
    delete itemScopedValues.itemStatus;
    delete itemScopedValues.lifecycleStage;
    const itemData: any = {
      itemFamilyId: placeholder.itemFamilyId,
      optionGroup: 'color',
    };
    itemData.optionName = itemName || placeholder.optionName;

    if (placeholder.itemOptionId) {
      itemData.specifiedId = placeholder.itemOptionId;
    }

    const itemOption = Object.assign(itemData, itemScopedValues);
    const addCheck = this.collectionElementActionValidator.isItemOptionAddable(itemOption, placeholder.itemFamily);
    if (!addCheck.isValid) {
      this.snackBar.open(addCheck.reason, '', { duration: 5000 });
      return;
    }
    return itemOption;
  }

  async createAndAssignFamily(placeholder: any, itemName: string) {
    const item = await this.createFamilyFromPlaceholder(placeholder, itemName);
    if (!item) return;

    const changes = {
      itemFamilyId: item.id,
      itemFamily: item,
      name: item.name,
    };
    this.setValuesOnPlaceholder('item', item, PropertyLevel.FAMILY, changes, placeholder);
    await this.setProjectItemValues(placeholder, item, PropertyLevel.FAMILY, changes);
    this.store.dispatch(CollectionManagerActions.updateCollectionDataEntity({ id: placeholder.id, changes }));
    return item;
  }
  async createFamilyFromPlaceholder(placeholder, itemName) {
    const itemFamily = this.buildItemFamilyFromPlaceholder(placeholder, itemName);

    this.webSocketService.sendMessage({
      sessionId: this.sessionId,
      action: 'ADD_LIVE_DATA_STREAM_TOPIC',
      topicsToSubscribe: [`item:${itemFamily.specifiedId}`],
    });

    const item = await new Entities().create({
      entityName: 'item',
      object: itemFamily,
    });
    return item;
  }

  private buildItemFamilyFromPlaceholder(placeholder, itemName) {
    const itemScopedValues = this.getCreationValues(placeholder, 'item', PropertyLevel.FAMILY);
    delete itemScopedValues.optionName; // TO DO: NEED TO HANDLE SCOPING OF ITEM VALUES!!!!

    // Set the item type id, if specificed on the PH
    // TODO:  Default to typepath set on the target assortment.
    if (placeholder.itemTypeId) {
      itemScopedValues.typeId = placeholder.itemTypeId;
    }

    if (placeholder.itemFamilyId) {
      itemScopedValues.specifiedId = placeholder.itemFamilyId;
    }

    const itemFamily = { name: itemName, ...itemScopedValues };
    const addCheck = this.collectionElementActionValidator.isItemFamilyAddable(itemFamily);
    if (!addCheck.isValid) {
      this.snackBar.open(addCheck.reason, '', { duration: 5000 });
      return;
    }

    return itemFamily;
  }

  private getFamilyItemsToCreateFromPlaceholders(placeholdersByFamilyName: any): any[] {
    const itemsToCreate = [];
    for (const familyName of Object.keys(placeholdersByFamilyName)) {
      const relatedPhs = placeholdersByFamilyName[familyName];
      if (relatedPhs.length > 0) {
        const newItemToCreate = this.buildItemFamilyFromPlaceholder(relatedPhs[0], familyName);
        if (newItemToCreate) {
          itemsToCreate.push(newItemToCreate);
        }
      }
    }

    return itemsToCreate;
  }

  private getOptionItemsToCreateFromPlaceholders(placeholders: any[]): any[] {
    const optionItemsToCreate = [];
    for (const placeholder of placeholders) {
      const newOption = this.buildItemOptionFromPlaceholder(placeholder, placeholder.optionName);
      if (newOption) {
        optionItemsToCreate.push(newOption);
      }
    }

    return optionItemsToCreate;
  }

  private async batchCreateItemsAndProjectItemsForPlaceholders(
    placeholders: any[],
    itemsToCreate: any[],
    level: string,
  ): Promise<{ items: Item[]; projectItems: ProjectItem[] }> {
    const itemsToCreateBatches = chunk(itemsToCreate, 25);
    const createdItems: Item[] = [];
    const createProjectItems: ProjectItem[] = [];
    const errors: any[] = [];

    const promises: Array<Promise<any>> = [];
    for (const itemsToCreate of itemsToCreateBatches) {
      const promise = limit(async () => {
        const { items, projectItems, error } = await this.createItemsAndProjectItemsForPlaceholders(
          placeholders,
          itemsToCreate,
          level,
        );
        createdItems.push(...items);
        createProjectItems.push(...projectItems);
        if (error) {
          errors.push(error);
        }

        return;
      });

      promises.push(promise);
    }

    await Promise.all(promises);

    if (errors.length > 0 || createdItems.length !== itemsToCreate.length) {
      const errorMessage = this.buildItemCreationErrorMessage(errors, createdItems.length, itemsToCreate.length, level);
      this.snackBar.open(errorMessage, '', { duration: 8000 });
    }

    return { items: createdItems, projectItems: createProjectItems };
  }

  private async createItemsAndProjectItemsForPlaceholders(
    placeholders: any[],
    itemsToCreate: any[],
    level: string,
  ): Promise<{ items: Item[]; projectItems: ProjectItem[]; error?: any }> {
    const { items: createdItems, error } = await this.createItems(itemsToCreate);
    if (error) {
      return { items: [], projectItems: [], error };
    }

    try {
      const upsertedProjectItems = await this.upsertFamilyProjectItemsFromPlaceholders(
        placeholders,
        createdItems,
        level,
      );
      return { items: createdItems, projectItems: upsertedProjectItems };
    } catch (error) {
      if (createdItems?.length) {
        new Entities().batchDelete({ entityName: 'item', ids: createdItems.map((item) => item.id) });
      }

      return { items: [], projectItems: [], error };
    }
  }

  private async createItems(itemsToCreate: any[]): Promise<{ items: Item[]; error?: any }> {
    this.webSocketService.sendMessage({
      sessionId: this.sessionId,
      action: 'ADD_LIVE_DATA_STREAM_TOPIC',
      topicsToSubscribe: itemsToCreate.map((item) => `item:${item.specifiedId}`),
    });

    try {
      const items = await new Entities().batchCreate({ entityName: 'item', objects: itemsToCreate });
      return { items };
    } catch (error) {
      return { items: [], error };
    }
  }

  private getCreationValues(placeholder: any, type: string, level: string) {
    const values: any = {};
    let itemProps;
    if (level === PropertyLevel.FAMILY) {
      itemProps = this.typeMap[type].typeProperties.filter(
        (prop) => prop.propertyLevel === null || prop.propertyLevel !== PropertyLevel.OPTION,
      );
    } else {
      // For option, we should not include 'all' level properties or else the option's all-level properties will be copied from family.
      itemProps = this.typeMap[type].typeProperties.filter(
        (prop) => prop.propertyLevel !== PropertyLevel.FAMILY && prop.propertyLevel !== PropertyLevel.ALL,
      );
    }
    itemProps.forEach((prop) => {
      if (placeholder[prop.slug]) {
        if (
          [PropertyType.UserList, PropertyType.ObjectReference, PropertyType.TypeReference].includes(prop.propertyType)
        ) {
          // for a reference prop, just pass the object id.
          values[prop.slug + 'Id'] = placeholder[prop.slug].id;
        } else {
          values[prop.slug] = placeholder[prop.slug];
        }
      }
    });
    return values;
  }

  public setValuesOnPlaceholder(type: string, item: any, level: string, changes: any, placeholder: any) {
    changes = changes || {};
    item = item || {};
    const itemProps = this.getRelevantProperties(type, level);
    itemProps.forEach((prop) => {
      // Set the value on placeholder if needed
      if (
        !['createdOn', 'updatedOn'].includes(prop.slug) &&
        this.shouldSetPropertyOnPlaceholder(prop, type, level, placeholder, PropertyLevel.ALL) &&
        this.shouldSetPropertyOnPlaceholder(prop, type, level, placeholder, PropertyLevel.OVERRIDABLE)
      ) {
        changes[prop.slug] = item[prop.slug] || null;
        if (
          [PropertyType.ObjectReference, PropertyType.TypeReference, PropertyType.UserList].includes(prop.propertyType)
        ) {
          changes[prop.slug + 'Id'] = item[prop.slug + 'Id'] || null;
        }
      }
    });
  }

  public shouldSetPropertyOnPlaceholder(
    prop: TypeProperty,
    type: string,
    level: string,
    placeholder: any,
    propertyLevel,
  ): boolean {
    if (level !== PropertyLevel.FAMILY || prop.propertyLevel !== propertyLevel) {
      return true;
    } else if (type === 'item' && placeholder?.itemOption) {
      return false;
    } else if (type === 'project-item' && placeholder?.itemOption?.projectItem) {
      return false;
    }
    return true;
  }

  public async setProjectItemValues(placeholder: any, item: any, level: string, changes: any) {
    const projectItemValues = this.getCreationValues(placeholder, 'project-item', level);
    projectItemValues.addedFromSource = 'CREATE';
    if (Object.keys(projectItemValues).length > 0) {
      const projectItem = await this.projectItemUpdateService.upsertProjectItem(item.id, projectItemValues);
      this.setValuesOnPlaceholder('project-item', projectItem, level, changes, placeholder);
      item.projectItem = projectItem;
    }
  }

  private async upsertFamilyProjectItemsFromPlaceholders(
    placeholders: any[],
    items: Item[],
    level: string,
  ): Promise<ProjectItem[]> {
    const projectItemsToUpsert = [];
    for (const item of items) {
      const placeholder =
        level === PropertyLevel.FAMILY
          ? placeholders.find((ph) => ph.itemFamilyId === item.id)
          : placeholders.find((ph) => ph.itemOptionId === item.id && ph.itemFamilyId === item.itemFamilyId);

      if (!placeholder) {
        const itemName = level === PropertyLevel.FAMILY ? item.name : `${item.name} - ${item.optionName}`;
        console.log(`No placeholder found for created ${level} item: ${itemName}`);
        continue;
      }

      const projectItemValues = this.getCreationValues(placeholder, 'project-item', level);
      projectItemsToUpsert.push({ id: item.id, changes: { ...projectItemValues, addedFromSource: 'CREATE' } });
    }

    if (projectItemsToUpsert.length) {
      return await this.projectItemUpdateService.batchUpsert(projectItemsToUpsert);
    }

    return [];
  }

  private updatePlanPlaceholders(placeholdersToUpdate: any[]) {
    if (placeholdersToUpdate.length > 0) {
      this.store.dispatch(CollectionManagerActions.updateCollectionDataEntities({ entities: placeholdersToUpdate }));
    }
  }

  public getRelevantProperties(type: string, level: string): TypeProperty[] {
    let properties = [];
    if (level === PropertyLevel.FAMILY) {
      properties = this.typeMap[type].typeProperties.filter(
        (prop) => prop.propertyLevel === null || prop.propertyLevel !== PropertyLevel.OPTION,
      );
    } else {
      properties = this.typeMap[type].typeProperties.filter((prop) => prop.propertyLevel !== PropertyLevel.FAMILY);
    }
    return properties;
  }

  private async deriveOptionNameOfPlaceholders(placeholders: any[]) {
    const optionNameProp = (await new Types().getType({ path: 'item' })).typeProperties.find(
      (prop) => prop.slug === 'optionName',
    );
    for (const placeholder of placeholders) {
      if (optionNameProp.formulaFunction) {
        const clonedItem = ObjectUtil.cloneDeep(placeholder);
        await FormulaFunctionProcessor.processFormulaFunctionsForEntity(clonedItem, [optionNameProp], {});
        if (clonedItem.optionName) {
          Object.assign(placeholder, { optionName: clonedItem.optionName });
        }
      }
    }
  }

  private buildItemCreationErrorMessage(
    errors: any[],
    createdItemsCount: number,
    totalItemsToCreateCount: number,
    level: string,
  ): string {
    const hasUniqunessError = errors.some((error) =>
      Boolean(
        error instanceof BadRequestError &&
          Array.isArray(error.message) &&
          error.message.some((error) => error?.propertiesMustBeUnique?.length),
      ),
    );

    const errorQuantityLanguage = createdItemsCount === 0 && totalItemsToCreateCount > 0 ? 'All' : 'Some';

    if (hasUniqunessError) {
      const uniqueProperties: string[] = [
        ...new Set(
          errors
            .flatMap((error) => error?.message.flatMap((error) => error?.propertiesMustBeUnique))
            .filter((uniqueProp) => !!uniqueProp),
        ),
      ];

      return `Uniqueness Error: ${errorQuantityLanguage} ${level} Items failed to be created. The following properties must be unique: ${uniqueProperties.join(', ')}`;
    }

    return `Error: ${errorQuantityLanguage} ${level} Items failed to be created.`;
  }
}
