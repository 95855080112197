<app-data-cell-edit-text
  #editCell
  *ngIf="propertyType === 'string'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  (focusOnCell)="handleFocusOnCell($event)"
></app-data-cell-edit-text>
<app-data-cell-edit-number
  #editCell
  *ngIf="propertyType === 'number'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  (focusOnCell)="handleFocusOnCell($event)"
></app-data-cell-edit-number>
<app-data-cell-edit-currency
  #editCell
  *ngIf="propertyType === 'currency'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  (focusOnCell)="handleFocusOnCell($event)"
></app-data-cell-edit-currency>
<app-data-cell-edit-percentage
  #editCell
  *ngIf="propertyType === 'percent'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  (focusOnCell)="handleFocusOnCell($event)"
></app-data-cell-edit-percentage>
<app-data-cell-edit-boolean
  #editCell
  *ngIf="propertyType === 'boolean'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
></app-data-cell-edit-boolean>
<app-data-cell-edit-date
  #editCell
  *ngIf="propertyType === 'date'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  (focusOnCell)="handleFocusOnCell($event)"
  [valueCleared]="valueCleared"
></app-data-cell-edit-date>
<app-data-cell-edit-item-object-reference
  #editCell
  *ngIf="isItemFamilyOrOptionProperty"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  [data]="data"
>
</app-data-cell-edit-item-object-reference>
<app-data-cell-edit-object-reference
  #editCell
  *ngIf="propertyType === 'object_reference' && !isItemFamilyOrOptionProperty"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  [data]="data"
>
</app-data-cell-edit-object-reference>
<app-data-cell-edit-type-reference
  #editCell
  *ngIf="propertyType === 'type_reference'"
  [value]="value"
  [property]="property"
  [value]="data.itemTypeId"
  (valueChange)="handleChange($event)"
  [data]="data"
>
</app-data-cell-edit-type-reference>
<app-data-cell-edit-size-range
  #editCell
  *ngIf="propertyType === 'size_range'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  [data]="data"
>
</app-data-cell-edit-size-range>

<app-data-cell-edit-select
  style="width: 100%"
  #editCell
  *ngIf="propertyType === 'choice'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  [data]="data"
  [display]="display"
></app-data-cell-edit-select>
<app-data-cell-edit-multi-select
  style="width: 100%"
  #editCell
  *ngIf="propertyType === 'multi_select'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  [data]="data"
  [display]="display"
></app-data-cell-edit-multi-select>
<app-data-cell-edit-text-area
  #editCell
  *ngIf="propertyType === 'text'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  (endEditChange)="handleEndEdit()"
></app-data-cell-edit-text-area>
<app-data-cell-edit-user
  style="width: 100%"
  #editCell
  *ngIf="propertyType === 'userList'"
  [value]="value"
  [property]="property"
  (valueChange)="handleChange($event)"
  [data]="data"
  [display]="display"
></app-data-cell-edit-user>
