import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EntityReference } from '@contrail/sdk';
import { CollectionDataMappedPropertiesBySlug, EntityFormulaProcessor, Type } from '@contrail/types';
import { ObjectDiff, ObjectUtil } from '@contrail/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { nanoid } from 'nanoid';
import { of as observableOf, from, combineLatest } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, tap, withLatestFrom, take, filter } from 'rxjs/operators';
import { CommentsActions } from 'src/app/common/comments/comments-store';
import { Comment } from 'src/app/common/comments/comments.service';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { PlansService } from 'src/app/plans/plans.service';
import { PlansActions, PlansSelectors, RootStoreState } from 'src/app/root-store';
import { CollectionManagerActions, CollectionManagerSelectors } from '..';
import { CollectionDataEntity, CollectionManagerService } from '../../collection-manager.service';
import { PlaceholderUtil } from '../../placeholders/placeholder-util';
import { UndoRedoActionType, UndoRedoObject } from '../../undo-redo/undo-redo-objects';
import { FormulaFunctionProcessor } from '@contrail/types';
import { SortObjects } from 'src/app/common/components/sort/sort-objects';
import { SortDefinition } from '@components/sort/sort-definition';
import { SortDirection } from '../../sort/sort-definition';
import { CollectionStatusMessageService } from '../../side-panel/status-messages/collection-status-message.service';
import { FilterDefinition } from '@common/types/filters/filter-definition';
import { FilterObjects } from '@contrail/filters';
import { CollectionElementValidator } from '../../collection-element-validator/collection-element-validator';
import { CollectionManagerViewService } from '../../collection-manager-view.service';
import { DocumentHistoryActionTypes } from '@common/document-history/document-history-store/document-history.actions';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { CollectionStatusMessageTypes } from '@common/collection-status-message/collection-status-message';
import { CollectionAlertTypes } from '../../side-panel/status-messages/collection-status.interfaces';
import { CreateCollectionEntitiesAtIndicesAction } from './collection-elements.actions';
import { AsyncErrorsActions } from '@common/errors/async-errors-store';
import { ErrorActionType } from '@common/errors/async-errors-store/async-errors.state';
import { Assortment } from '@common/assortments/assortments-store/assortments.state';

const MAX_WEBSOCKET_PAYLOAD_SIZE = 120 * 1024; // 120 KB

@Injectable()
export class CollectionElementEffects {
  constructor(
    private actions$: Actions,
    private collectionManagerService: CollectionManagerService,
    private planService: PlansService,
    private store: Store<RootStoreState.State>,
    private undoRedoService: UndoRedoService,
    private webSocketService: WebSocketService,
    private snackBar: MatSnackBar,
    private collectionElementValidator: CollectionElementValidator,
    private collectionStatusMessageService: CollectionStatusMessageService,
    private viewService: CollectionManagerViewService,
  ) {}

  private enableBenchmarking = false;
  private validationFunctionErrorMessage = 'Validation Error: Update(s) could not be processed.';

  loadCollectionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionElementsActionTypes.LOAD_COLLECTION_DATA),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        this.store.dispatch(
          CollectionManagerActions.setCollectionElementsLoaded({
            loaded: false,
          }),
        );

        const startTime = new Date().getTime();
        if (this.enableBenchmarking) {
          console.log(`loadCollectionData: START`);
        }

        const planId = action.id;
        const collectionData$ = from(this.collectionManagerService.getCollectionData(planId));
        const typeDefinitions$ = this.store.select(CollectionManagerSelectors.typeDefinitions).pipe(
          filter((types) => !!types),
          take(1),
        );
        const currentPlan$ = this.store.select(PlansSelectors.currentPlan).pipe(
          filter((plan) => !!plan),
          take(1),
        );
        const editorMode$ = this.store.select(PlansSelectors.editorMode).pipe(
          filter((mode) => !!mode),
          take(1),
        );

        return combineLatest([collectionData$, typeDefinitions$, currentPlan$, editorMode$]).pipe(
          switchMap(async ([collectionData, typeDefinitions, currentPlan]) => {
            try {
              if (this.enableBenchmarking) {
                const benchmark = new Date().getTime() - startTime;
                console.log(`loadCollectionData: Loaded all plan data: ${benchmark} ms`);
              }

              const placeholders = await PlaceholderUtil.postProcessLoadedPlaceholders(
                collectionData,
                currentPlan?.targetAssortment,
                store.auth.authContext.currentOrg.orgConfig,
              );

              if (this.enableBenchmarking) {
                const benchmark = new Date().getTime() - startTime;
                console.log(`loadCollectionData: Processed all plan placeholder data: ${benchmark} ms`);
              }

              if (typeof Worker !== 'undefined') {
                await this.collectionStatusMessageService.launchWorkerToProcessAlertsOnAllEntities(
                  currentPlan,
                  placeholders,
                  typeDefinitions,
                );
              } else {
                await this.collectionStatusMessageService.processAlertsOnAllEntities(
                  currentPlan,
                  placeholders,
                  typeDefinitions,
                );
              }

              return placeholders;
            } catch (e) {
              console.error(e);
              throw e;
            }
          }),
          tap(() =>
            this.store.dispatch(
              CollectionManagerActions.setCollectionElementsLoaded({
                loaded: true,
              }),
            ),
          ),
          map((data) => {
            if (this.enableBenchmarking) {
              const benchmark = new Date().getTime() - startTime;
              console.log(`loadCollectionData: END: ${benchmark} ms`);
            }

            this.store.dispatch(
              CollectionManagerActions.setScrollVerticalPercentage({
                percentage: 0,
              }),
            );
            return CollectionManagerActions.loadCollectionDataSuccess({ data });
          }),
          catchError((error) => observableOf(CollectionManagerActions.loadCollectionDataFailure({ error }))),
        );
      }),
    ),
  );

  loadCollectionSnapshotData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentHistoryActionTypes.LOAD_CURRENT_ENTITY_SNAPSHOT_SUCCESS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        this.store.dispatch(
          CollectionManagerActions.setCollectionElementsLoaded({
            loaded: false,
          }),
        );
        return from(this.store.select(DocumentHistorySelectors.currentEntitySnapshot)).pipe(
          switchMap(async (snapshotData) => {
            console.log('data', snapshotData);
            try {
              let placeholders = [];
              if (snapshotData) {
                placeholders = await PlaceholderUtil.postProcessLoadedPlaceholders(
                  snapshotData.snapshot.planPlaceholders,
                  store.plans?.currentPlan?.targetAssortment,
                  store.auth.authContext.currentOrg.orgConfig,
                );

                await this.collectionStatusMessageService.processAlertsOnAllEntities(
                  store.plans?.currentPlan,
                  placeholders,
                  store.collectionManager.typeDefinitions,
                );
              }
              return placeholders;
            } catch (e) {
              console.error(e);
              throw e;
            }
          }),
          tap(() =>
            this.store.dispatch(
              CollectionManagerActions.setCollectionElementsLoaded({
                loaded: true,
              }),
            ),
          ),
          map((data) => {
            this.store.dispatch(
              CollectionManagerActions.setScrollVerticalPercentage({
                percentage: 0,
              }),
            );
            return CollectionManagerActions.loadCollectionDataSuccess({ data });
          }),
          catchError((error) => observableOf(CollectionManagerActions.loadCollectionDataFailure({ error }))),
        );
      }),
    ),
  );

  sortCollectionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CollectionManagerActions.CollectionElementsActionTypes.LOAD_COLLECTION_DATA_SUCCESS,
        PlansActions.PlansActionTypes.SYNC_PLAN_ROW_ORDER,
        CollectionManagerActions.CollectionViewsActionTypes.SET_CURRENT_VIEW_DEFINITION,
        CollectionManagerActions.CollectionManagerActionTypes.SET_SORTS_SUCCESS,
      ),
      withLatestFrom(this.store),
      map(([action, store]: [any, RootStoreState.State]) => {
        // console.log('collectionElements', store.collectionManager.collectionElements);
        // console.log('view row order', store.collectionManager.currentViewDefinition);
        // console.log('plan row order', store.plans.planRowOrder.rowOrder);

        const currentViewDefinition = store.collectionManager.currentViewDefinition;
        const planRowOrder = store.plans.planRowOrder.rowOrder;
        const snapshotData = store.documentHistory.currentEntitySnapshot;
        let data =
          ObjectUtil.cloneDeep(action.data) || Object.values(store.collectionManager.collectionElements.entities);

        if (!currentViewDefinition) {
          if (snapshotData) {
            data = SortObjects.sort(data, null, snapshotData.snapshot.planRowOrder.rowOrder);
          } else {
            data = SortObjects.sort(data, null, planRowOrder);
          }
        } else if (currentViewDefinition.viewType !== 'pivot' && data.length > 0) {
          // do not sort pivot view or without data
          const sorts = currentViewDefinition.sorts;
          const sortOrder: SortDefinition[] = [];
          sorts?.forEach((sort) => {
            sortOrder.push({
              propertySlug: sort.propertySlug,
              direction:
                sort.direction === SortDirection.ASCENDING ? SortDirection.ASCENDING : SortDirection.DESCENDING,
              propertyType: sort.propertyType,
            });
          });
          if (snapshotData) {
            data = SortObjects.sort(data, sortOrder, snapshotData.snapshot.planRowOrder.rowOrder);
          } else {
            data = SortObjects.sort(data, sortOrder, planRowOrder);
          }
        }
        return CollectionManagerActions.setCollectionData({ data });
      }),
    ),
  );

  filterCollectionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CollectionManagerActions.CollectionElementsActionTypes.LOAD_COLLECTION_DATA_SUCCESS,
        CollectionManagerActions.CollectionManagerActionTypes.SET_FILTER_DEFINITION_SUCCESS,
      ),
      delay(1),
      withLatestFrom(this.store),
      map(([action, store]: [any, RootStoreState.State]) => {
        const filterDef: FilterDefinition = store.collectionManager.filterDefinition;
        let rawData =
          ObjectUtil.cloneDeep(action.data) || Object.values(store.collectionManager.collectionElements.entities);

        if (!filterDef?.filterCriteria?.propertyCriteria || filterDef.filterCriteria.propertyCriteria.length === 0) {
          return CollectionManagerActions.setFilteredEntityIds({
            filteredEntityIds: null,
          });
        } else {
          // Only filter data by alerts if alert is in the filter criteria.
          if (
            filterDef.filterCriteria.propertyCriteria.find(
              (propCriteria) => propCriteria.filterPropertyDefinition?.slug === this.viewService.alertProperty.slug,
            )
          ) {
            const collectionStatusMessages = Object.values(store.collectionManager.collectionStatusMessages.entities);
            rawData = ObjectUtil.cloneDeep(rawData);
            rawData.forEach((ph) => {
              ph[this.viewService.alertProperty.slug] = [];
              if (ph.isDropped) {
                ph[this.viewService.alertProperty.slug].push(CollectionAlertTypes.DROPPED);
              }

              if (collectionStatusMessages.length > 0) {
                const hasWarningToSet = Boolean(
                  !ph[this.viewService.alertProperty.slug].includes(CollectionAlertTypes.WARNING) &&
                    collectionStatusMessages
                      .filter((message) => message.type === CollectionStatusMessageTypes.WARNING)
                      .find((message) => message.collectionElementId === ph.id),
                );

                if (hasWarningToSet) {
                  ph[this.viewService.alertProperty.slug].push(CollectionAlertTypes.WARNING);
                }

                const hasMissingFromSourceAlertToSet = Boolean(
                  !ph[this.viewService.alertProperty.slug].includes(CollectionAlertTypes.NOT_IN_SOURCE_ASSORTMENT) &&
                    collectionStatusMessages
                      .filter((message) => message.type === CollectionStatusMessageTypes.NOT_IN_SOURCE_ASSORTMENT)
                      .find((message) => message.collectionElementId === ph.id),
                );

                if (hasMissingFromSourceAlertToSet) {
                  ph[this.viewService.alertProperty.slug].push(CollectionAlertTypes.NOT_IN_SOURCE_ASSORTMENT);
                }
              }
            });
          }
        }
        const filteredEntityIds = new Set(FilterObjects.filter(rawData, filterDef.filterCriteria).map((ph) => ph.id));
        return CollectionManagerActions.setFilteredEntityIds({
          filteredEntityIds,
        });
      }),
    ),
  );
  /**
   * @deprecated
   * This is not used anywhere.
   */
  createCollectionEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITY),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          console.log('CollectionElementEffects: createCollectionEntity');
          const planId = store.plans.currentPlan.id;
          const id = nanoid(16);
          const newPh = Object.assign(
            { ...action.entity },
            {
              planId,
              id,
              specifiedId: id,
              createdOn: new Date().toISOString(),
            },
          );

          this.store.dispatch(
            CollectionManagerActions.createCollectionDataEntitySuccess({
              entity: newPh,
            }),
          );
          try {
            const phClone = ObjectUtil.cloneDeep(newPh);
            delete phClone.id;
            this.collectionManagerService.createEntity(phClone);
          } catch (error) {
            this.snackBar.open(error, '', { duration: 2000 });
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * @deprecated
   * This is not used anywhere.
   */
  createCollectionEntitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITY_SUCCESS),
      withLatestFrom(this.store),
      map(([action, store]: [any, RootStoreState.State]) => {
        // add newly created row into the rowOrder
        const updatedPlanRowOrder = ObjectUtil.cloneDeep(store.plans.planRowOrder);
        // By default, insert a new row at the end of the table
        let newRowIndex = updatedPlanRowOrder.rowOrder.length;
        const selectedIds = store.collectionManager.selectedEntityIds;
        // If a row is selected, use the selected row's index.
        const selectedRowIndex = updatedPlanRowOrder.rowOrder.indexOf(selectedIds[0]);
        if (selectedRowIndex > -1) {
          newRowIndex = selectedRowIndex;
        }
        this.store.dispatch(CollectionManagerActions.removeSelectedEntityIds({ ids: selectedIds }));
        updatedPlanRowOrder.rowOrder.splice(newRowIndex, 0, action.entity.id);
        // dispatch updated planRowOrder first for performance.
        this.store.dispatch(PlansActions.syncPlanRowOrder({ planRowOrder: updatedPlanRowOrder }));

        console.log('CollectionElementEffects: createCollectionEntitySuccess');
        this.webSocketService.sendSessionEvent({
          eventType: 'CREATE_COLLECTION_ENTITY',
          changes: { id: action.entity.id, ...action.entity },
        });
        return CollectionManagerActions.addCollectionEntity({
          entity: action.entity,
        });
      }),
    ),
  );

  createCollectionEntitiesAtIndices$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_AT_INDICES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [CreateCollectionEntitiesAtIndicesAction, RootStoreState.State]) => {
          try {
            const { entitiesByIndex } = action;
            await this.collectionManagerService.createEntitiesAtIndices(entitiesByIndex);
          } catch (error) {
            console.error('Failed to create Plan Placeholders at indices.', error);
          }

          this.store.dispatch(CollectionManagerActions.createCollectionEntitiesAtIndicesComplete());
        }),
      ),
    { dispatch: false },
  );

  createCollectionEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          try {
            const newPhs = action.entities.map((entity) => {
              return this.collectionManagerService.buildEntityForCreate(entity);
            });

            this.store.dispatch(
              CollectionManagerActions.createCollectionDataEntitiesSuccess({
                entities: newPhs,
              }),
            );

            // add newly created row into the rowOrder
            const updatedPlanRowOrder = ObjectUtil.cloneDeep(store.plans.planRowOrder);
            // By default, insert a new row at the end of the table
            let newRowIndex = updatedPlanRowOrder.rowOrder.length;
            if (action.targetRowIndex && action.targetRowIndex > -1) {
              newRowIndex = action.targetRowIndex;
            }
            const ids = newPhs.map((entity) => entity.id);
            let rowIds = [];
            if (action.rowOrder) {
              action.rowOrder.forEach((obj) => {
                const currentIndex = updatedPlanRowOrder.rowOrder.findIndex((rowId) => rowId === obj.id);
                if (currentIndex > -1) {
                  updatedPlanRowOrder.rowOrder.splice(currentIndex, 1);
                }
                updatedPlanRowOrder.rowOrder.splice(obj.index, 0, obj.id);
                updatedPlanRowOrder.order = rowIds.push({
                  id: obj.id,
                  index: obj.index,
                });
              });
              newRowIndex = null;
            } else {
              rowIds = ids;
              updatedPlanRowOrder.rowOrder.splice(newRowIndex, 0, ...ids);
            }
            // dispatch updated planRowOrder first for performance.
            setTimeout(() => {
              this.store.dispatch(
                PlansActions.syncPlanRowOrder({
                  planRowOrder: updatedPlanRowOrder,
                }),
              );
              this.webSocketService.sendSessionEvent({
                // send row order update through websocket
                eventType: 'SYNC_PLAN_ROW_ORDER',
                changes: updatedPlanRowOrder,
              });
            }, 1); // doing this to allow the collection data store to be updated first.

            let undoActionUuid = null;
            const changesList: UndoRedoObject[] = [];
            if (!action.skipRecordingUndoRedo) {
              ids.forEach((id, index) => {
                changesList.push({
                  id,
                  changes: ObjectUtil.cloneDeep(action.entities[index]),
                  undoChanges: null,
                  objectType: 'placeholder',
                });
              });
              changesList.push({
                id: updatedPlanRowOrder.id,
                changes: { rowIds: ids, targetRowIndex: newRowIndex },
                undoChanges: { rowIds, targetRowIndex: -1 },
                objectType: 'rowOrder',
              });
              const undoAction = this.undoRedoService.addUndo({
                actionType: UndoRedoActionType.CREATE_PLACEHOLDER,
                changesList,
              });
              undoActionUuid = undoAction.uuid;
            }

            try {
              const clonedPhs = [];
              newPhs.forEach((entity) => {
                const phClone = ObjectUtil.cloneDeep(entity);
                delete phClone.id;
                clonedPhs.push(phClone);
              });

              const isInsertingRowAtEndOfPlan =
                !action.rowOrder && newRowIndex === store.plans.planRowOrder.rowOrder.length;
              const batchCreatePlaceholdersLimit = 100;
              await this.collectionManagerService.createEntities(clonedPhs, batchCreatePlaceholdersLimit);
              if (!isInsertingRowAtEndOfPlan || clonedPhs.length > batchCreatePlaceholdersLimit) {
                await this.planService.updatePlanRowOrder(updatedPlanRowOrder.id, {
                  rowIds,
                  targetRowIndex: newRowIndex,
                });
              }
            } catch (error) {
              this.store.dispatch(
                AsyncErrorsActions.addAsyncError({
                  error,
                  errorType: ErrorActionType.CREATE_PLACEHOLDERS,
                  undoActionUuid,
                }),
              );

              throw new Error(error);
            }
          } catch (error) {
            console.error('Failed to create Plan Placeholders.', error);
          }

          this.store.dispatch(CollectionManagerActions.createCollectionDataEntitiesComplete());
        }),
      ),
    { dispatch: false },
  );

  createCollectionEntitiesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionElementsActionTypes.CREATE_COLLECTION_DATA_ENTITIES_SUCCESS),
      withLatestFrom(this.store),
      map(([action, store]: [any, RootStoreState.State]) => {
        console.log('CollectionElementEffects: createCollectionEntitiesSuccess');

        if (action?.shouldAddToRowOrder && action.entities.length) {
          const newIds = action.entities.map((entity) => entity.id);
          const currentPlanRowOrder = store.plans.planRowOrder;
          const updatedRowOrder = [...currentPlanRowOrder.rowOrder, ...newIds];
          const updatedPlanRowOrder = {
            ...currentPlanRowOrder,
            rowOrder: updatedRowOrder,
          };

          setTimeout(() => {
            this.planService.updatePlanRowOrder(currentPlanRowOrder.id, {
              rowIds: newIds,
              targetRowIndex: currentPlanRowOrder.rowOrder.length,
            });
            this.store.dispatch(
              PlansActions.syncPlanRowOrder({
                planRowOrder: updatedPlanRowOrder,
              }),
            );
            this.webSocketService.sendSessionEvent({
              eventType: 'SYNC_PLAN_ROW_ORDER',
              changes: updatedPlanRowOrder,
            });
          }, 1);
        }

        this.webSocketService.sendSessionEvent({
          eventType: 'CREATE_COLLECTION_ENTITIES',
          changes: action.entities,
        });
        return CollectionManagerActions.addCollectionEntities({
          entities: action.entities,
        });
      }),
    ),
  );

  addCollectionEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.ADD_COLLECTION_ENTITIES),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          if (store.collectionManager.filterDefinition.filterCriteria.propertyCriteria.length > 0) {
            console.log(action.entities);
            const filteredEntityIds: Set<string> =
              new Set(store.collectionManager.collectionElements.filteredEntityIds) || new Set([]);
            action.entities.map((entity) => entity.id).forEach((id) => filteredEntityIds.add(id));
            this.store.dispatch(
              CollectionManagerActions.setFilteredEntityIds({
                filteredEntityIds,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  updateCollectionDataEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITY),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          // console.log('updateCollectionDataEntity: action: ', action);
          const entity = store.collectionManager.collectionElements.entities[action.id];
          const typeDefinitions = store.collectionManager.typeDefinitions;
          const placeholderType = typeDefinitions['plan-placeholder'];
          const assortment = store.plans?.currentPlan?.targetAssortment;
          const entityCopy: CollectionDataEntity = ObjectUtil.cloneDeep(entity);
          const formulaContext = { assortment, previousObj: entityCopy };
          const undoActionUuid = this.addUndoRedo(action, entityCopy);

          const formulaTypeMap = EntityFormulaProcessor.buildCollectionDataTypePropertiesBySlugMap(typeDefinitions);
          const changes = await this.applyFormula({
            type: placeholderType,
            changes: action.changes,
            entityCopy,
            formulaContext,
            formulaTypeMap,
          });

          const diff: ObjectDiff[] = ObjectUtil.compareDeep(entity, changes, '');
          const finalChanges: any = {};
          diff.forEach((change) => {
            if (!(this.isNullOrEmpty(change.newValue) && this.isNullOrEmpty(change.oldValue))) {
              // If change is an object, ObjectUtil.compareDeep will produce something like (propertyName:"sizeRange.sizes")
              // If change is an object, use the entire object as changes.
              if (change.propertyName.includes('.')) {
                const prop = change.propertyName.split('.')[0];
                finalChanges[prop] = changes[prop];
              } else {
                finalChanges[change.propertyName] = change.newValue;
              }
            }
          });
          finalChanges.updatedOn = new Date();

          const validationCheck = await this.collectionStatusMessageService.validateChangesAndSetAlerts({
            id: action.id,
            changes: finalChanges,
          });
          if (validationCheck.hasErrors) {
            this.snackBar.open(this.validationFunctionErrorMessage, '', {
              duration: 5000,
            });
            return;
          }

          const broadcast = action.broadcast !== undefined ? action.broadcast : true;
          this.store.dispatch(
            CollectionManagerActions.applyCollectionEntityChanges({
              id: action.id,
              changes: finalChanges,
              broadcast,
              skipErrorValidation: true,
            }),
          );

          delete entityCopy.itemOption;
          delete entityCopy.itemFamily;
          this.collectionManagerService.updateEntity({
            entity: entityCopy,
            changes: finalChanges,
            undoActionUuid,
            selectedCell: action?.selectedCell,
          });
        }),
      ),
    { dispatch: false },
  );

  updateCollectionDataEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.UPDATE_COLLECTION_DATA_ENTITIES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          try {
            const undoRedoActions: UndoRedoObject[] = [];
            const typeDefinitions = store.collectionManager.typeDefinitions;
            const placeholderType = typeDefinitions['plan-placeholder'];
            const assortment = store.plans?.currentPlan?.targetAssortment;
            const formulaTypeMap = EntityFormulaProcessor.buildCollectionDataTypePropertiesBySlugMap(typeDefinitions);
            // console.log('updateCollectionDataEntity: action: ', action);
            const entities = action.entities; // Includes all itemOption, itemFamily changes.
            const batchChanges = [];
            const entityChanges = [];
            for (let index = 0; index < entities.length; index++) {
              const entityObj = entities[index];
              // console.log('UPDATING:', entityObj);
              const entity = store.collectionManager.collectionElements.entities[entityObj.id];
              // console.log('UPDATING: existing', entity);
              const entityCopy: CollectionDataEntity = ObjectUtil.cloneDeep(entity);

              const formulaContext = { assortment, previousObj: entityCopy };
              const changes = await this.applyFormula({
                type: placeholderType,
                changes: entityObj.changes,
                entityCopy,
                formulaContext,
                formulaTypeMap,
              });

              // console.log("changes: ", changes)
              const diff: ObjectDiff[] = ObjectUtil.compareDeep(entity, changes, '');
              // console.log("diff: ", diff)
              const finalChanges: any = {};
              diff.forEach((change) => {
                if (!(this.isNullOrEmpty(change.newValue) && this.isNullOrEmpty(change.oldValue))) {
                  // Looks for flattened property e.g. itemFamily.name. Should only happen for object-referenced prop
                  const index = change.propertyName.indexOf('.');
                  if (index > -1) {
                    const objRefKey = change.propertyName.substring(0, index);
                    if (!finalChanges.hasOwnProperty(objRefKey)) {
                      // this will make sure that the whole object is passed e.g. itemFamily
                      finalChanges[objRefKey] = entityObj.changes[objRefKey];
                    }
                  } else {
                    finalChanges[change.propertyName] = change.newValue;
                  }
                }
              });
              finalChanges.updatedOn = new Date();
              const collectionEntityChanges = ObjectUtil.cloneDeep(finalChanges);
              console.log(finalChanges);

              if (Object.keys(finalChanges).length > 0) {
                if (finalChanges.hasOwnProperty('itemOptionId') && !collectionEntityChanges.itemOptionId) {
                  collectionEntityChanges.itemOption = null;
                }

                batchChanges.push({
                  id: entityObj.id,
                  changes: collectionEntityChanges,
                });
                if (finalChanges.itemOption) {
                  // Remove itemFamily and itemOption.
                  delete finalChanges.itemOption;
                  delete finalChanges.itemFamily;
                }
                entityChanges.push({ id: entityObj.id, changes: finalChanges });
              }

              const undoChanges = ObjectUtil.cloneDeep(entity);
              for (const key in collectionEntityChanges) {
                const change = collectionEntityChanges[key];
                const undoChange = undoChanges[key];

                if (change && !undoChange) {
                  undoChanges[key] = null;
                }
              }

              undoRedoActions.push({
                id: entityObj.id,
                changes: collectionEntityChanges,
                undoChanges,
              });
            }

            const undoActionUuid = !action.skipRecordingUndoRedo
              ? this.undoRedoService.addUndo({
                  actionType: UndoRedoActionType.UPDATE_PLACEHOLDER,
                  changesList: undoRedoActions,
                })?.uuid
              : action?.undoActionUuid;

            const validationCheck = await this.collectionStatusMessageService.validateChangesAndSetAlerts({
              changes: batchChanges,
            });
            if (validationCheck.hasErrors) {
              this.snackBar.open(this.validationFunctionErrorMessage, '', {
                duration: 5000,
              });
            }

            const batchChangesToApply = this.filterOutEntityChangesWithErrors(batchChanges, validationCheck);
            const entityChangesToApply = this.filterOutEntityChangesWithErrors(entityChanges, validationCheck);
            if (!entityChangesToApply || entityChangesToApply.length === 0) {
              return;
            }

            this.store.dispatch(
              CollectionManagerActions.batchApplyCollectionEntityChanges({
                changes: batchChangesToApply,
                broadcast: true,
                skipErrorValidation: true,
              }),
            );

            await this.collectionManagerService.updateEntities({
              changes: entityChangesToApply,
              undoActionUuid,
            });
          } catch (error) {
            console.error('Failed to update Plan Placeholders.', error);
          }

          this.store.dispatch(CollectionManagerActions.updateCollectionDataEntitiesComplete());
        }),
      ),
    { dispatch: false },
  );

  applyCollectionEntityChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.APPLY_COLLECTION_ENTITY_CHANGES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const changesWithNoRestrictedProperties = PlaceholderUtil.removeRestrictedPropertiesOnPlaceholder(
            ObjectUtil.cloneDeep(action.changes),
            store.collectionManager.typeDefinitions,
          );

          this.store.dispatch(
            CollectionManagerActions.applyCollectionEntityChangesSuccess({
              ...action,
              changes: changesWithNoRestrictedProperties,
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  applyCollectionEntityChangesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.APPLY_COLLECTION_ENTITY_CHANGES_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          if (!action.skipErrorValidation) {
            const validationCheck = await this.collectionStatusMessageService.validateChangesAndSetAlerts(action);
            if (validationCheck.hasErrors) {
              this.snackBar.open(this.validationFunctionErrorMessage, '', {
                duration: 5000,
              });
              return;
            }
          }

          // console.log('applyCollectionEntityChanges: ', action.changes);
          if (action.broadcast) {
            this.webSocketService.sendMessage({
              sessionId: store.userSessions.currentSessionId,
              action: 'SESSION_EVENT',
              event: {
                eventType: 'UPDATE_COLLECTION_ENTITY',
                changes: { id: action.id, ...action.changes }, // this sends the whoe entity right now.
              },
            });
          }
        }),
      ),
    { dispatch: false },
  );

  batchApplyCollectionEntityChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.BATCH_APPLY_COLLECTION_ENTITY_CHANGES),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const changesWithNoRestrictedProperties = [];
          for (const update of action.changes) {
            const adjustedPlaceholderChanges = PlaceholderUtil.removeRestrictedPropertiesOnPlaceholder(
              ObjectUtil.cloneDeep(update.changes),
              store.collectionManager.typeDefinitions,
            );
            changesWithNoRestrictedProperties.push({
              ...update,
              changes: adjustedPlaceholderChanges,
            });
          }

          this.store.dispatch(
            CollectionManagerActions.batchApplyCollectionEntityChangesSuccess({
              ...action,
              changes: changesWithNoRestrictedProperties,
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  batchApplyCollectionEntityChangesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.BATCH_APPLY_COLLECTION_ENTITY_CHANGES_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          if (!action.skipErrorValidation) {
            const validationCheck = await this.collectionStatusMessageService.validateChangesAndSetAlerts(action);
            if (validationCheck.hasErrors) {
              this.snackBar.open(this.validationFunctionErrorMessage, '', {
                duration: 5000,
              });
              return;
            }
          }

          // console.log('applyCollectionEntityChanges: ', action.changes);

          if (action.broadcast) {
            await this.sendChunks(action.changes, store, 'SESSION_EVENT', 'UPDATE_COLLECTION_ENTITIES');
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * @deprecated
   * This is not used anywhere.
   */
  deleteCollectionDataEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITY),
      mergeMap((action: any) => {
        return from(this.collectionManagerService.deleteEntity(action.entity)).pipe(
          map((entity) =>
            CollectionManagerActions.deleteCollectionDataEntitySuccess({
              entity,
            }),
          ),
          catchError((error) =>
            observableOf(
              CollectionManagerActions.deleteCollectionDataEntityFailure({
                error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  /**
   * @deprecated
   * This is not used anywhere.
   */
  deleteCollectionEntitySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITY_SUCCESS),
      withLatestFrom(this.store),
      map(([action, store]: [any, RootStoreState.State]) => {
        const updatedPlanRowOrder = ObjectUtil.cloneDeep(store.plans.planRowOrder);
        const rowIndex = updatedPlanRowOrder.rowOrder.indexOf(action.entity.id);
        if (rowIndex > -1) {
          updatedPlanRowOrder.rowOrder.splice(rowIndex, 1);
        }
        // dispatch updated planRowOrder first for performance.
        this.store.dispatch(PlansActions.syncPlanRowOrder({ planRowOrder: updatedPlanRowOrder }));
        this.webSocketService.sendMessage({
          sessionId: store.userSessions.currentSessionId,
          action: 'SESSION_EVENT',
          event: {
            eventType: 'DELETE_COLLECTION_ENTITY',
            changes: { id: action.entity.id, ...action.entity },
          },
        });
        return CollectionManagerActions.removeCollectionEntity({
          entity: action.entity,
        });
      }),
    ),
  );

  deleteCollectionDataEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITIES),
        tap(async (action: any) => {
          this.store.dispatch(
            CollectionManagerActions.deleteCollectionDataEntitiesSuccess({
              ids: action.ids,
              skipRecordingUndoRedo: action.skipRecordingUndoRedo,
            }),
          );
          try {
            this.collectionManagerService.deleteEntities(action.ids);
          } catch (error) {
            this.snackBar.open(error, '', { duration: 2000 });
          }
        }),
      ),
    { dispatch: false },
  );

  deleteCollectionEntitiesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionElementsActionTypes.DELETE_COLLECTION_DATA_ENTITIES_SUCCESS),
      withLatestFrom(this.store),
      map(([action, store]: [any, RootStoreState.State]) => {
        // remove deleted rows from the rowOrder
        const updatedPlanRowOrder = ObjectUtil.cloneDeep(store.plans.planRowOrder);
        const changesList: UndoRedoObject[] = [];
        const deletedRowOrders = action.ids
          .filter((id) => updatedPlanRowOrder.rowOrder.indexOf(id) > -1)
          .map((id) => ({
            id,
            index: updatedPlanRowOrder.rowOrder.indexOf(id),
          }));

        action.ids.forEach((id) => {
          const rowIndex = updatedPlanRowOrder.rowOrder.indexOf(id);
          const entity = store.collectionManager.collectionElements.entities[id];
          if (!action.skipRecordingUndoRedo) {
            changesList.push({
              id,
              changes: entity.id,
              undoChanges: ObjectUtil.cloneDeep(entity),
              objectType: 'placeholder',
            });
          }
          if (rowIndex > -1) {
            updatedPlanRowOrder.rowOrder.splice(rowIndex, 1);
          }
        });
        if (!action.skipRecordingUndoRedo) {
          deletedRowOrders.forEach((rowOrderChanges) => {
            changesList.push({
              id: updatedPlanRowOrder.id,
              changes: null,
              undoChanges: rowOrderChanges,
              objectType: 'rowOrder',
            });
          });
          this.undoRedoService.addUndo({
            actionType: UndoRedoActionType.DELETE_PLACEHOLDER,
            changesList,
          });
        }
        // dispatch updated planRowOrder first for performance.
        setTimeout(() => {
          this.store.dispatch(PlansActions.syncPlanRowOrder({ planRowOrder: updatedPlanRowOrder }));
          this.webSocketService.sendSessionEvent({
            // send row order update through websocket
            eventType: 'SYNC_PLAN_ROW_ORDER',
            changes: updatedPlanRowOrder,
          });
        }, 1); // doing this to allow the collection data store to be updated first.

        this.webSocketService.sendMessage({
          sessionId: store.userSessions.currentSessionId,
          action: 'SESSION_EVENT',
          event: {
            eventType: 'DELETE_COLLECTION_ENTITIES',
            changes: { ids: action.ids },
          },
        });
        return CollectionManagerActions.removeCollectionEntities({
          ids: action.ids,
        });
      }),
    ),
  );

  removeCollectionEntities$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.REMOVE_COLLECTION_ENTITIES),
        withLatestFrom(this.store),
        map(([action, store]: [any, RootStoreState.State]) => {
          if (store.collectionManager.filterDefinition.filterCriteria.propertyCriteria.length > 0) {
            const filteredEntityIds: Set<string> = new Set(
              store.collectionManager.collectionElements.filteredEntityIds,
            );
            action.ids.forEach((id) => filteredEntityIds.delete(id));
            this.store.dispatch(
              CollectionManagerActions.setFilteredEntityIds({
                filteredEntityIds,
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );
  acceptCommentChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CommentsActions.CommentsActionTypes.ACCEPT_COMMENT),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          const comment: Comment = action.comment;
          const changeSuggestion = comment.changeSuggestion;
          console.log('Collection Element Store: Comment Accepted: ', action.comment);
          if (!changeSuggestion) {
            return;
          }
          const ref = new EntityReference(comment.ownedByReference);
          const currentEntity = store.collectionManager.collectionElements.entities[ref.id];

          const changes = {};
          const editCheck = await this.collectionElementValidator.isEditableForPropertySlug(
            currentEntity,
            changeSuggestion.changeDetails.propertySlug,
          );
          if (editCheck.editable) {
            // only editable property can be updated
            changes[changeSuggestion.changeDetails.propertySlug] = changeSuggestion.changeDetails.newValue;
            this.store.dispatch(
              CollectionManagerActions.updateCollectionDataEntity({
                id: ref.id,
                changes,
              }),
            );
          } else {
            this.snackBar.open(editCheck.reason, '', { duration: 5000 });
            setTimeout(() => {
              // Reopen the comment here because the comment has been closed by the CommentsActions. Doing it here since the CommentsActions
              // is in the common folder and has no access to the CollectionElementsState
              this.store.dispatch(CommentsActions.reopenComment({ comment }));
            }, 100);
          }
        }),
      ),
    { dispatch: false },
  );

  private async applyFormula(options: {
    type: Type;
    changes: { [property: string]: any };
    entityCopy: CollectionDataEntity;
    formulaContext: { assortment: Assortment; previousObj: CollectionDataEntity };
    formulaTypeMap: CollectionDataMappedPropertiesBySlug;
  }) {
    const { type, changes, entityCopy, formulaContext, formulaTypeMap } = options;

    // Apply changes
    const updatedEntity = Object.assign({}, entityCopy, changes);

    // Apply formula changes
    const formulaTarget = ObjectUtil.cloneDeep(updatedEntity);

    // COMPUTES BASED ON JAVASCRIPT FUNCTION
    await EntityFormulaProcessor.processAndSetFormulaResultsOnEntity(formulaTarget, {
      type,
      formulaContext,
      collectionDataMappedTypeProperties: formulaTypeMap,
      enableDebugLogs: false,
    });

    // Ideally, compute the real difference at this point using ObjectUtil, and send just the changes...
    // But for now, we need to make the changes = the entityCopy to pick up the formula modifications
    const finalChanges = Object.assign(formulaTarget, changes);
    return finalChanges;
  }

  private addUndoRedo(action: any, entityCopy: any): string {
    if (!action.skipRecordingUndoRedo) {
      // skip undo/redo record for real undo/redo updates
      const undoChanges = {};
      Object.keys(action.changes).forEach((att) => {
        undoChanges[att] = ObjectUtil.cloneDeep(entityCopy[att]);
      });
      const addedUndo = this.undoRedoService.addUndo({
        actionType: UndoRedoActionType.UPDATE_PLACEHOLDER,
        changesList: [{ id: action.id, changes: action.changes, undoChanges }],
      });
      return addedUndo.uuid;
    }
  }

  private filterOutEntityChangesWithErrors(entityChanges, validationCheck) {
    if (!validationCheck.hasErrors) return entityChanges;

    const entityIdsWithErrors = validationCheck.errorMessages.map((error) => error.collectionElementId);
    return entityChanges.filter((change) => !entityIdsWithErrors.includes(change.id));
  }

  private isNullOrEmpty(value: any): boolean {
    if (typeof value === 'boolean' || typeof value === 'number') {
      return false;
    }

    return !value;
  }

  updateSubscribedTopics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.UPDATE_LIVE_DATA_TOPICS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          let entities: CollectionDataEntity[] = action.placeholders;

          if (!entities || entities.length === 0) {
            return;
          }

          if (entities[0].changes) {
            entities = entities.map((entity) => entity.changes);
          }

          const itemFamilyIds = entities.map((entity) => entity.itemFamilyId).filter((x) => x);
          const itemOptionIds = entities.map((entity) => entity.itemOptionId).filter((x) => x);
          const uniqueItemIds = [...new Set([...itemFamilyIds, ...itemOptionIds])];

          let projectId;
          this.store
            .select(WorkspacesSelectors.currentWorkspace)
            .pipe(
              take(1),
              tap(async (ws) => {
                projectId = ws.projectId;
              }),
            )
            .subscribe();

          const projectItemIds = uniqueItemIds
            .map((id) => {
              return projectId + ':' + id;
            })
            .filter((x) => x);

          const itemReferences = uniqueItemIds.map((id) => 'item:' + id);
          const projectItemReferences = projectItemIds.map((id) => 'project-item:' + id);
          const references = [...itemReferences, ...projectItemReferences];

          if (references.length > 0) {
            this.webSocketService.sendMessage({
              sessionId: store.userSessions.currentSessionId,
              action: 'ADD_LIVE_DATA_STREAM_TOPIC',
              topicsToSubscribe: references,
            });
          }
        }),
      ),
    { dispatch: false },
  );

  resubscribeToAllTopics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CollectionManagerActions.CollectionElementsActionTypes.RESUBSCRIBE_ALL_LIVE_DATA_TOPICS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          this.webSocketService.sendMessage({
            sessionId: store.userSessions.currentSessionId,
            action: 'RESUBSCRIBE_ALL_LIVE_DATA_TOPICS',
          });
        }),
      ),
    { dispatch: false },
  );

  private chunkArrayBySize(array, maxSize) {
    let chunks = [];
    let currentChunk = [];
    let currentSize = 0;

    array.forEach((item) => {
      let serializedItem = JSON.stringify(item);
      let itemSize = new TextEncoder().encode(serializedItem).length;

      if (currentSize + itemSize > maxSize) {
        chunks.push(currentChunk);
        currentChunk = [];
        currentSize = 0;
      }

      currentChunk.push(item);
      currentSize += itemSize;
    });

    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }

    return chunks;
  }

  private async sendChunks(changes, store, action, eventType) {
    const chunks = this.chunkArrayBySize(changes, MAX_WEBSOCKET_PAYLOAD_SIZE);
    console.log('Sending chunks:', chunks.length);

    for (const chunk of chunks) {
      await this.webSocketService.sendMessage({
        sessionId: store.userSessions.currentSessionId,
        action: action,
        event: {
          eventType: eventType,
          changes: chunk,
        },
      });
    }
  }
}
