import { Injectable } from '@angular/core';
import { AuthSelectors } from '@common/auth/auth-store';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Subject } from 'rxjs/internal/Subject';
import { v4 as uuid } from 'uuid';
import { RootStoreState } from 'src/app/root-store';

@Injectable({
  providedIn: 'root',
})
export class PublishService {
  authContext: any = {};

  private TIMEOUT_POLLING_COUNT_LIMIT = 325;
  private DEBOUNCE_TIME = 5000; // wait 5 seconds before the next check

  private jobPollingCount = new Map<string, number>();

  private pendingJobs: Array<string> = [];
  private pendingJobsSubject: Subject<string[]> = new BehaviorSubject(null);
  pendingJobsObservable: Observable<string[]> = this.pendingJobsSubject.asObservable();

  private completedJobs = '';
  private completedJobsSubject: Subject<any> = new BehaviorSubject(null);
  completedJobsObservable$: Observable<any> = this.completedJobsSubject.asObservable();

  private errorSubject: Subject<boolean> = new BehaviorSubject(null);
  errorObservable$: Observable<boolean> = this.errorSubject.asObservable();

  private initiatePublishSubject: Subject<any> = new BehaviorSubject(null);
  initiatePublishObservable$: Observable<string> = this.initiatePublishSubject.asObservable();

  constructor(store: Store<RootStoreState.State>) {
    this.initiatePublishObservable$.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((value) => {
      if (value) {
        this.getPublishStatus(value);
      }
    });
    store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => (this.authContext = authContext));
  }

  async initPublish(options: any) {
    const jobId = await this.getJobId(options.plan.id);
    this.initPublishPolling(options.plan.id, jobId);
    options.data.jobId = jobId;
    await new Entities().create({ entityName: 'plan', id: options.plan.id, object: options.data, relation: 'publish' });
    return jobId;
  }

  initPublishPolling(planId: string, jobId: string) {
    this.pendingJobs.push(jobId);
    this.pendingJobsSubject.next(this.pendingJobs);

    this.initiatePublishSubject.next({ planId, jobId });
    this.updatePollingCount(jobId);
  }

  async getPublishStatus(options) {
    console.log(`getPublishStatus - jobId ${JSON.stringify(options.jobId)}`);
    const option = {
      entityName: 'plan',
      relation: `publish/${options.jobId}`,
    };
    const jobStatusData = await new Entities().get(option);
    console.log(` jobStatusData ${JSON.stringify(jobStatusData)}`);

    if (jobStatusData && ['completed', 'error'].includes(jobStatusData?.status)) {
      this.pendingJobs = this.pendingJobs.filter((data) => data !== options.jobId);
      this.completedJobs = jobStatusData;

      this.completedJobsSubject.next(this.completedJobs);
      this.pendingJobsSubject.next(this.pendingJobs);
    }

    if (this.pendingJobs.length > 0) {
      const nextPendingJob = this.pendingJobs[0];
      try {
        this.updatePollingCount(nextPendingJob);
        this.initiatePublishSubject.next({ jobId: nextPendingJob });
      } catch (err) {
        console.error(' Error : for jobId ' + nextPendingJob, err);

        this.pendingJobs = this.pendingJobs.filter((data) => data !== nextPendingJob);
        this.pendingJobsSubject.next(this.pendingJobs);

        if (this.pendingJobs?.length) {
          this.initiatePublishSubject.next(this.pendingJobs[0]);
        }
        this.errorSubject.next(true);
      }
    }
  }

  private updatePollingCount(jobId) {
    let count = 0;
    if (this.jobPollingCount.has(jobId)) {
      count = this.jobPollingCount.get(jobId);
    }

    count += 1;
    this.jobPollingCount.set(jobId, count);
    if (count > this.TIMEOUT_POLLING_COUNT_LIMIT) {
      throw Error(
        `Publish timed out for jobid ${jobId}. Exceeded ${this.TIMEOUT_POLLING_COUNT_LIMIT} polling attempts with debounce duration of ${this.DEBOUNCE_TIME / 1000} seconds`,
      );
    }
  }

  public resetJobQueue() {
    this.pendingJobsSubject.next([]);
    this.completedJobsSubject.next(null);
    this.errorSubject.next(false);

    this.jobPollingCount = new Map<string, number>();
    this.pendingJobs = [];
  }

  async getJobId(planId: string) {
    return `${await uuid()}:publish:${planId}:${this.authContext.user.id}:${this.authContext.currentOrg.orgSlug}`;
  }
}
