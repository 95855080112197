export class FeatureFlag {
  id: string;

  createdOn?: Date;
  updatedOn?: Date;

  createdById?: string;
  updatedById?: string;

  createdBy?: any;
  updatedBy?: any;

  orgId?: string;

  featureName: string;
}

export enum Feature {
  EXTENSIONS = 'EXTENSIONS',
  ASSET_LIBRARY = 'ASSET_LIBRARY',
  ITEM_CONTEXT = 'ITEM_CONTEXT',
  COLOR_LIBRARY = 'BOARD_COLORS',
  SHOWROOM = 'SHOWROOM',
  DEV_TABS = 'DEV_TABS',
  ITEM_BOARDS = 'ITEM_BOARDS',
  DOCUMENT_GENERATION = 'DOCUMENT_GENERATION',
  PROJECT_GOALS = 'PROJECT_GOALS',
  ITEM_CHOOSER_LEVEL_SELECTION = 'ITEM_CHOOSER_LEVEL_SELECTION',
  MATERIAL_MVP = 'MATERIAL_MVP',
}
