import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs';
import { ObjectUtil } from '@contrail/util';
import { PlansSelectors, RootStoreState } from '@rootstore';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { EditorMode } from '../collection-manager-store/collection-manager.state';
import { CollectionManagerActions, CollectionManagerSelectors } from '../collection-manager-store';
import { CollectionDataEntity, CollectionManagerService } from '../collection-manager.service';
import { PlaceholderUtil } from './placeholder-util';
import { AuthSelectors } from '@common/auth/auth-store';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderItemCopyService {
  private editorMode: string = EditorMode.VIEW;
  private typeDefinitions;
  private targetAssortment;
  private orgConfig;

  constructor(
    private store: Store<RootStoreState.State>,
    private collectionManagerService: CollectionManagerService,
    private snackBar: MatSnackBar,
  ) {
    this.store.select(PlansSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }

  async copyPlaceholderItems(placeholderIds: string[]) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }

    const placeholdersWithItems = this.getPlaceholdersWithItemsByIds(placeholderIds);
    if (!placeholdersWithItems?.length) {
      return;
    }

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    this.setCurrentPlanAndOrgData();

    try {
      const newPlaceholders = await this.batchCopyPlaceholderItemsInChunks(placeholdersWithItems);

      if (newPlaceholders.length) {
        const processedPlaceholders = await PlaceholderUtil.postProcessLoadedPlaceholders(
          newPlaceholders,
          this.targetAssortment,
          this.orgConfig,
        );

        this.store.dispatch(
          CollectionManagerActions.createCollectionDataEntitiesSuccess({
            entities: processedPlaceholders,
            shouldAddToRowOrder: true,
          }),
        );
      }

      if (newPlaceholders.length !== placeholdersWithItems.length) {
        this.snackBar.open('Error: Some Items failed to copy.', '', { duration: 5000 });
      }
    } catch (err) {
      this.snackBar.open('Error: Failed to copy Items.', '', { duration: 5000 });
      console.error(err);
    }

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  getPlaceholdersWithItemsByIds(placeholderIds: string[]): CollectionDataEntity[] {
    const placeholders = [];

    this.store
      .select(CollectionManagerSelectors.displayedData)
      .pipe(
        take(1),
        tap((data) => {
          placeholders.push(
            ...ObjectUtil.cloneDeep(
              data.filter((placeholder) => placeholderIds.includes(placeholder.id) && placeholder.itemFamilyId),
            ),
          );
        }),
      )
      .subscribe();

    return placeholders;
  }

  private async batchCopyPlaceholderItemsInChunks(placeholdersToCopy: CollectionDataEntity[]) {
    const placeholdersGroupedByItemFamily = placeholdersToCopy.reduce((result, placeholder) => {
      (result[placeholder.itemFamilyId] = result[placeholder.itemFamilyId] || []).push(placeholder);
      return result;
    }, {});

    const minChunkSize = 5;
    const placeholderIdsToCopyInChunks = [];

    let currentChunk = [];
    let currentChunkSize = 0;

    for (const itemFamilyId in placeholdersGroupedByItemFamily) {
      const placeholders = placeholdersGroupedByItemFamily[itemFamilyId];
      currentChunk = currentChunk.concat(placeholders);
      currentChunkSize += placeholders.length;

      if (
        currentChunkSize >= minChunkSize ||
        Object.keys(placeholdersGroupedByItemFamily).indexOf(itemFamilyId) ===
          Object.keys(placeholdersGroupedByItemFamily).length - 1
      ) {
        placeholderIdsToCopyInChunks.push(currentChunk.map((placeholder) => placeholder.id));
        currentChunk = [];
        currentChunkSize = 0;
      }
    }

    const copyPlaceholderPromises = placeholderIdsToCopyInChunks.map(async (chunk, index) => {
      await new Promise((resolve) => setTimeout(resolve, index * 3000)); // stagger each batch call by 3 seconds to spread out the server load
      return this.batchCopyPlaceholderItems(chunk);
    });

    const createdPlaceholders = await Promise.all(copyPlaceholderPromises);
    return createdPlaceholders.reduce((results, placeholderChunk) => results.concat(placeholderChunk), []);
  }

  private async batchCopyPlaceholderItems(placeholderIdsToCopy: string[]): Promise<CollectionDataEntity[]> {
    try {
      return await this.collectionManagerService.copyEntities(placeholderIdsToCopy);
    } catch (err) {
      console.error('Failed to batch copy placeholder items.', err);
      return [];
    }
  }

  private setCurrentPlanAndOrgData() {
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        take(1),
        tap((typeDefs) => {
          this.typeDefinitions = typeDefs;
        }),
      )
      .subscribe();

    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((plan) => {
          this.targetAssortment = plan?.targetAssortment;
        }),
      )
      .subscribe();

    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((org) => {
          this.orgConfig = org.orgConfig;
        }),
      )
      .subscribe();
  }
}
