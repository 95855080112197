import { Assortment } from '@common/assortments/assortments-store/assortments.state';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Plan {
  id?: string;
  name?: string;
  updatedOn?: string;
  createdOn?: string;
  updatedBy?: any;
  createdBy?: any;
  coverPhoto?: string;
  isArchived?: boolean;
  planSummary?: {
    count: number;
    aggregates: any;
  };
  selected?: boolean;
  workspaceId?: string;
  targetAssortment?: PlanTargetAssortment;
  targetAssortmentId?: string;
  workspace?: any;
  sourceAssortments?: Array<any>;
  planGoals?: Array<PlanGoal>;
}
export interface PlanRowOrder {
  id?: string;
  updatedOn?: string;
  createdOn?: string;
  updatedBy?: any;
  createdBy?: any;
  planId?: any;
  rowOrder?: Array<string>;
}

export interface PlanTargetAssortment extends Assortment {
  id: string;
  name: string;
  sourceAssortmentIds?: Array<string>;
  sourceAssortments?: Array<any>;
}

interface PlansState extends EntityState<Plan> {}

export interface State {
  plans: PlansState;
  currentPlan: Plan;
  planRowOrder: PlanRowOrder;
  plansLoaded: boolean;
  editorMode: string;
}
export const plansEntityAdapter: EntityAdapter<Plan> = createEntityAdapter<Plan>({});

export const plansInitialState = {
  plans: plansEntityAdapter.getInitialState({}),
  currentPlan: null,
  planRowOrder: null,
  plansLoaded: false,
  editorMode: null,
};

export enum PlanGoalAggregationType {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
}

export interface PlanGoal {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;
  typeId?: string;
  orgId?: string;

  planId?: string;
  propertySlug?: string;
  targetValue?: any; // the target value
  aggregationType?: PlanGoalAggregationType; // sum, average, min, max, count
}
