import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { TypeProperty } from '@contrail/types';
import { RootStoreState } from '@rootstore';
import { AuthSelectors } from '@common/auth/auth-store';

@Component({
  selector: 'app-object-reference-chip',
  templateUrl: './object-reference-chip.component.html',
  styleUrls: ['./object-reference-chip.component.scss'],
})
export class ObjectReferenceChipComponent implements OnChanges {
  @Input() object: any;
  @Input() typeProperty: TypeProperty;
  @Input() objectProperties?: Array<TypeProperty>;
  @Input() edit = false;
  @Input() maxDisplayLength = 16;
  @Input() allowDisplayTextShortening = true;
  @Input() clickable = true;
  @Output() clearValue = new EventEmitter();
  @Output() clicked = new EventEmitter();

  public objectName: string;
  public objectNumber: string;
  public hasStatus: boolean;
  public statusHexColor: string;
  private statusColors: Array<string>;

  constructor(private store: Store<RootStoreState.State>) {
    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((currentOrg) => {
          this.statusColors = currentOrg?.orgConfig?.itemStatusHexColors;
        }),
      )
      .subscribe();
  }

  async ngOnChanges() {
    this.objectName = this.object.name;
    this.statusHexColor = null;

    if (this.object?.typeId) {
      const itemStatusSlug = this.object.lifecycleStage
        ? 'lifecycleStage'
        : this.object.itemStatus
          ? 'itemStatus'
          : null;
      if (itemStatusSlug && this.objectProperties) {
        const indexOfObjectStatus = this.getIndexOfPropertyValueInOptions(itemStatusSlug);
        this.statusHexColor = this.statusColors[indexOfObjectStatus] ?? this.statusColors[0];
        this.hasStatus = true;
      }
    }

    const isItemObject = this.typeProperty?.referencedTypeRootSlug === 'item';
    if (isItemObject) {
      this.setObjectNameForItem();
    }
  }

  private setObjectNameForItem() {
    const isItemOptionProperty = this.typeProperty.slug === 'itemOption';
    const isOptionReference = this.typeProperty.referencedTypeRole === 'option';

    if (isItemOptionProperty) {
      this.objectName = this.object.optionName;
      return;
    }

    if (isOptionReference) {
      this.objectName = `${this.object.name} - ${this.object.optionName}`;
      return;
    }

    this.objectName = this.object.name;
  }

  remove() {
    this.clearValue.emit();
  }

  viewDetails() {
    if (this.clickable) {
      this.clicked.emit();
    }
  }

  getIndexOfPropertyValueInOptions(propertySlug: string) {
    const property = this.objectProperties.find((p) => p.slug === propertySlug);
    if (!property?.options) return -1;

    return property.options.findIndex((option) => option.value === this.object[propertySlug]);
  }
}
