import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Entities, Types } from '@contrail/sdk';
import { EntityFormulaProcessor, FormulaFunctionProcessor, PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { Item } from '@common/items/item';
import { PlansSelectors, RootStoreState } from '@rootstore';
import { AuthSelectors } from '@common/auth/auth-store';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { UndoRedoActionType } from '../undo-redo/undo-redo-objects';
import { PlaceholderUtil } from './placeholder-util';

export const ITEM_ALREADY_EXISTS = 'Item already exists on plan and can not be added twice.';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderItemUpdateService {
  optionNameProp: TypeProperty;
  constructor(
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private undoRedoService: UndoRedoService,
  ) {}

  /** Applies update to placeholders when an item is updated
   */
  public processItemUpdateAndGetChangesToApply(
    changedItem: Item,
    itemChanges: any,
    skipRecordingUndoRedo?: boolean,
    optionUpdates?,
    skipOptionCheck = false,
  ): Promise<{ placeholderChanges: any[]; undoActionUuid?: string }> {
    console.log('handleItemUpdate: ', changedItem, itemChanges);
    return this.processItemUpdatesAndGetChangesToApply(
      [{ item: changedItem, changes: itemChanges }],
      skipRecordingUndoRedo,
      optionUpdates,
      skipOptionCheck,
    );
  }

  /** When an item changes, we need to update the state of the placeholders to reflect the items update
   * This may included updating many rows based on family level property changes.
   * @param updates A array of updates which includes inner objects for the item and the changes being applied.
   * @param skipRecordingUndoRedo   ?? boolean which can bypass adding to undo stack.
   * @param optionUpdates   ???
   */
  public async processItemUpdatesAndGetChangesToApply(
    updates: any[],
    skipRecordingUndoRedo?: boolean,
    optionUpdates?,
    skipOptionCheck = false,
  ): Promise<{ placeholderChanges: any[]; undoActionUuid?: string }> {
    const duplicateOptions: any[] = [];
    const familyOptionMap: any = {};
    const changes: any[] = [];
    const processedPlaceholders = {};
    const undoRedoChanges = [];
    const assortment = this.getAssortment();
    const orgConfig = this.getOrgConfig();

    for (let index = 0; index < updates.length; index++) {
      const changedItem = updates[index].item;
      const itemChanges = updates[index].changes;
      console.log('processItemUpdatesAndGetChangesToApply: ', changedItem, itemChanges);

      if (itemChanges.optionName && !skipOptionCheck) {
        if (!familyOptionMap[changedItem.itemFamilyId]) {
          const options = await new Entities().get({
            entityName: 'item',
            criteria: { itemFamilyId: changedItem.itemFamilyId },
          });
          familyOptionMap[changedItem.itemFamilyId] = options.filter((option) => option.roles.includes('color'));
        }
        if (
          familyOptionMap[changedItem.itemFamilyId].find(
            (option) => option.optionName && option.optionName === itemChanges.optionName,
          )
        ) {
          duplicateOptions.push(Object.assign(ObjectUtil.cloneDeep(changedItem), itemChanges));
          let ph = ObjectUtil.cloneDeep(this.getMatchingPlaceholders('itemOptionId', changedItem.id)[0]);
          ph.itemOption = ObjectUtil.cloneDeep(changedItem);
          ph = await PlaceholderUtil.postProcessPlaceholder(ph, null, orgConfig);
          changes.push({ id: ph.id, changes: ph });
          continue;
        }
      }

      const formulaContext = { previousObj: ObjectUtil.cloneDeep(changedItem) };
      let item = ObjectUtil.cloneDeep(changedItem);
      item = Object.assign(item, itemChanges);

      const itemType = await new Types().getType({ id: item.typeId });
      await EntityFormulaProcessor.processAndSetFormulaResultsOnEntity(item, {
        type: itemType,
        formulaContext,
        enableDebugLogs: false,
      });

      delete item.itemOptions;
      delete item.itemFamily;

      // Determine if item is family / option.
      if (!item.roles) {
        throw new Error('Missing role)s on item.');
      }
      const isFamily = item?.roles?.includes('family');
      const isOption = item?.roles?.includes('option');

      // Find all impacted placeholders
      const lookupKey = isFamily ? 'itemFamilyId' : 'itemOptionId';

      const impactedPlaceholders = this.getMatchingPlaceholders(lookupKey, item.id);
      const typeMap = this.getTypeMap();

      for (let j = 0; j < impactedPlaceholders.length; j++) {
        const ph = impactedPlaceholders[j];
        let phClone = processedPlaceholders[ph.id] ? processedPlaceholders[ph.id] : ObjectUtil.cloneDeep(ph);
        // let phClone = {id: ph.id}; // no need to clone the entire placeholder since we are only interested in item changes.
        let originalItemFamily;
        if (phClone.itemFamily) {
          originalItemFamily = ObjectUtil.cloneDeep(phClone.itemFamily);
        }
        if (isFamily) {
          if (!item.projectItem) {
            // when updating an item from the modal, the updated item is missing project-item
            const projectItem = ObjectUtil.cloneDeep(phClone.itemFamily.projectItem);
            item.projectItem = projectItem;
          }
          phClone.itemFamily = item;

          const clonedChanges = ObjectUtil.cloneDeep(itemChanges);
          delete clonedChanges.id;
          delete clonedChanges.smallViewableDownloadUrl;
          delete clonedChanges.mediumViewableDownloadUrl;
          delete clonedChanges.largeViewableDownloadUrl;
          delete clonedChanges.tinyViewableDownloadUrl;
          delete clonedChanges.mediumLargeViewableDownloadUrl;
          delete clonedChanges.roles;
          delete clonedChanges.itemStatus;
          delete clonedChanges.lifecycleStage;

          const propertiesForPropagation = await this.getFamilyChangesToPropagate(item.typeId, item); // Gets family level values to move to options.
          // console.log('Properties for propagation to option placeholders: ', propertiesForPropagation);

          // Copy down values from item to item option (which also happens on server)
          if (phClone.itemOption) {
            // optionUpdates contains up-to-date itemOption changes that can occur when mass-change involving
            // multiple objects take place at the same time(itemFamily, itemOption, placeholder)
            const originalOption = ObjectUtil.cloneDeep(phClone.itemOption);
            const optionId = phClone.itemOptionId;
            if (optionUpdates && optionUpdates[optionId]) {
              const optionChanges = optionUpdates[optionId].changes;

              phClone.itemOption = Object.assign(phClone.itemOption, optionChanges, propertiesForPropagation);
            } else {
              phClone.itemOption = Object.assign(phClone.itemOption, propertiesForPropagation);
            }
            const matchedProps = PlaceholderUtil.getMappedProperties(typeMap);
            const mappedProperties = matchedProps.filter((property) => property.propertyLevel !== 'all');
            ObjectUtil.applyChangesIfEqual(phClone.itemOption, originalItemFamily, itemChanges, mappedProperties);

            await EntityFormulaProcessor.processAndSetFormulaResultsOnEntity(phClone.itemOption, {
              type: itemType,
              formulaContext: {
                previousObj: originalOption,
              },
              enableDebugLogs: false,
            });
          }
          // Apply changes to the item family.
          phClone['itemFamily'] = Object.assign(phClone['itemFamily'], clonedChanges);
        }
        if (isOption) {
          if (!item.projectItem) {
            // when updating an item from the modal, the updated item is missing project-item
            const projectItem = ObjectUtil.cloneDeep(phClone.itemOption.projectItem);
            item.projectItem = projectItem;
          }
          phClone.itemOption = item;
        }

        // SHIFTS CHANGES FROM THE ITEMS INTO THE LOCAL / TOP LEVEL PH PROPERTIES
        phClone = await PlaceholderUtil.postProcessPlaceholder(phClone, assortment, orgConfig);

        // APPLY THE UPDATE (WITH OUT A PH SAVE) AND BROADCAST
        // REALLY SHOULDN'T BE BROADCASTING (WS) THE ENTIRE ENTITY, AS WE CAN GET OVERWRITES
        phClone = Object.assign({}, phClone);
        processedPlaceholders[ph.id] = phClone;
        const placeholderChanges = {
          id: phClone.id,
          changes: phClone,
        };

        const indexOfProcessedChange = changes.findIndex((change) => change.id === ph.id);
        if (indexOfProcessedChange >= 0) {
          changes[indexOfProcessedChange] = placeholderChanges;
        } else {
          changes.push(placeholderChanges);
        }
      }
      if (!skipRecordingUndoRedo) {
        const undoChanges = {};
        Object.keys(itemChanges).forEach((att) => {
          undoChanges[att] = ObjectUtil.cloneDeep(changedItem[att]);
        });
        undoRedoChanges.push({
          id: item.id,
          changes: itemChanges,
          undoChanges,
          scope: 'item',
          level: isOption ? 'option' : 'family',
        });
      }
    }

    const hasOptionNameBeenDeleted = changes.some((phUpdate) => {
      return phUpdate.changes.itemOption && !phUpdate.changes.optionName;
    });

    if (hasOptionNameBeenDeleted) {
      this.snackBar.open('Error: Option Name cannot be empty.', '', { duration: 5000 });
      throw new Error('Error: Option Name cannot be empty.');
    }

    const undoActionUuid = this.addUndoActionAndGetUuid(undoRedoChanges);
    return { placeholderChanges: changes, undoActionUuid };
  }

  public async deriveOptionNameIfApplicable(changedItem: any, itemChanges: any) {
    if (!this.optionNameProp) {
      this.optionNameProp = (await new Types().getType({ path: 'item' })).typeProperties.find(
        (prop) => prop.slug === 'optionName',
      );
    }
    if (this.optionNameProp.formulaFunction) {
      const clonedChangedItem = ObjectUtil.cloneDeep(changedItem);
      Object.assign(clonedChangedItem, itemChanges);
      await FormulaFunctionProcessor.processFormulaFunctionsForEntity(clonedChangedItem, [this.optionNameProp], {});
      if (clonedChangedItem.optionName && clonedChangedItem.optionName !== changedItem.optionName) {
        Object.assign(itemChanges, { optionName: clonedChangedItem.optionName });
      }
    }
  }

  /**
   * Fiugures out what properties should be 'propogated' to item options from an item family.
   * @param typeId
   * @param values
   * @returns
   */
  public async getFamilyChangesToPropagate(typeId: string, values: any) {
    const itemType: Type = await new Types().getType({ id: typeId });

    const properties: string[] = [];
    const typeProperties: any[] = itemType?.typeProperties
      ?.filter((property) => {
        return !['createdOn', 'updatedOn'].includes(property.slug);
      })
      .filter((property) => {
        return !property.propertyLevel || property.propertyLevel === 'family';
      })
      .filter((p) => p.slug !== 'optionName');

    typeProperties.forEach((typeProperty) => {
      properties.push(typeProperty.slug);
      if (PropertyType.ObjectReference === typeProperty.propertyType) {
        properties.push(typeProperty.slug + 'Id');
      }
    });

    const propertiesForPropogation = {};
    for (const property of Object.getOwnPropertyNames(values)) {
      if (properties.includes(property)) {
        propertiesForPropogation[property] = values[property];
      }
    }
    return propertiesForPropogation;
  }

  private addUndoActionAndGetUuid(changesList: any[]): string {
    if (changesList?.length) {
      const undoAction = this.undoRedoService.addUndo({
        actionType: UndoRedoActionType.UPDATE_PLACEHOLDER,
        changesList,
      });

      return undoAction?.uuid;
    }
  }

  private getTypeMap() {
    let typeMap;
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        take(1),
        tap((map) => {
          typeMap = map;
        }),
      )
      .subscribe();
    return typeMap;
  }

  private getAssortment() {
    let plan;
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((currentPlan) => {
          plan = currentPlan;
        }),
      )
      .subscribe();
    return plan.targetAssortment;
  }

  private getOrgConfig() {
    let org;
    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((currentOrg) => {
          org = currentOrg;
        }),
      )
      .subscribe();
    return org.orgConfig;
  }

  private getMatchingPlaceholders(index, value) {
    let matches = [];
    this.store
      .select(CollectionManagerSelectors.selectCollectionData)
      .pipe(take(1))
      .subscribe((phs) => {
        matches = phs.filter((ph) => ph[index] === value);
      });
    return matches;
  }
}
