<div class="item-box" cdkDrag [cdkDragDisabled]="!draggable" [attr.data-test]="'app-list-item-' + listItem.id">
  <div class="property-panel" [ngClass]="{ dropped: dropped }">
    <div *ngFor="let property of properties" class="item-meta">
      <span [ngSwitch]="property.propertyDefinition?.propertyType">
        <div *ngSwitchCase="'image'">
          <img
            draggable="false"
            *ngIf="getValue(property)"
            [attr.src]="getValue(property) | secureImage | async"
            class="thumbnail"
          />
          <mat-icon *ngIf="!getValue(property)" class="placeholder-img">panorama</mat-icon>
        </div>
        <div *ngSwitchCase="'object_reference'">
          <app-object-reference-chip
            [edit]="false"
            [object]="getValue(property)"
            [typeProperty]="property?.propertyDefinition"
            [maxDisplayLength]="maxRefDisplayLength"
            *ngIf="getValue(property)"
            (click)="handleObjectReferenceClick(property)"
          >
          </app-object-reference-chip>
        </div>
        <div *ngSwitchDefault>
          <span class="label">{{ property.propertyDefinition?.label }}:</span>{{ getValue(property) }}
        </div>
      </span>
    </div>
  </div>
  <div>
    <img
      src="/assets/images/drop.svg"
      style="height: 15px; width: 15px"
      matTooltip="Item is dropped."
      matTooltipPosition="right"
      *ngIf="dropped"
    />
    <div
      class="warning"
      *ngIf="(messages$ | async) && !dropped"
      matTooltipPosition="right"
      [matTooltip]="messages$ | async"
      matTooltipClass="multiline-tooltip"
    >
      <mat-icon>warning</mat-icon>
    </div>
  </div>
</div>
