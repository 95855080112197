import { Injectable } from '@angular/core';
import { Types } from '@contrail/sdk';
import { EntityFormulaProcessor, Type } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { PlansSelectors, RootStoreState } from '@rootstore';
import { Item } from '@common/items/item';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';
import { AuthSelectors } from '@common/auth/auth-store';
import { CollectionManagerSelectors } from '../collection-manager-store';
import { PlaceholderUtil } from '../placeholders/placeholder-util';
import { UndoRedoActionType } from '../undo-redo/undo-redo-objects';
import { Project } from '@contrail/entity-types';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

@Injectable({
  providedIn: 'root',
})
export class PlaceholderProjectItemUpdateService {
  private projectItemType: Type;
  constructor(
    private store: Store<RootStoreState.State>,
    private undoRedoService: UndoRedoService,
  ) {}

  /** Applies update to placeholders when an item is updated
   */
  public async processProjectItemUpdateAndGetChangesToApply(
    changeItem: Item,
    changes: any,
    skipRecordingUndoRedo?: boolean,
  ): Promise<{ placeholderChanges: any[]; undoActionUuid?: string }> {
    console.log('handleItemUpdate: ', changeItem, changes);
    return this.processProjectItemUpdatesAndGetChangesToApply([{ item: changeItem, changes }], skipRecordingUndoRedo);
  }

  /** When an project-item changes, we need to update the state of the placeholders to reflect the update
   * This may included updating many rows based on family level property changes.
   * @param updates A array of updates which includes inner objects for the item and the changes being applied.
   * @param skipRecordingUndoRedo   boolean which can bypass adding to undo stack.
   */
  public async processProjectItemUpdatesAndGetChangesToApply(
    updates: any[],
    skipRecordingUndoRedo?: boolean,
    undoRedo = false,
  ): Promise<{ placeholderChanges: any[]; undoActionUuid?: string }> {
    const typeMap = this.getTypeMap();
    const project = this.getProject();
    const assortment = this.getAssortment();

    this.projectItemType = typeMap['project-item'];
    const phClones = [];
    const processedPlaceholders = {};
    const undoRedoChanges = [];
    for (let index = 0; index < updates.length; index++) {
      const item = updates[index].item;
      const originalProjectItem = ObjectUtil.cloneDeep(item.projectItem);
      const projectItemChanges = updates[index].changes;
      console.log('processProjectItemUpdatesAndGetChangesToApply: ', item, projectItemChanges);

      // Determine if item is family / option.
      if (!item.roles) {
        throw new Error('Missing role)s on item.');
      }
      const isFamily = item?.roles?.includes('family');
      const isOption = item?.roles?.includes('option');

      // Find all impacted placeholders
      const lookupKey = isFamily ? 'itemFamilyId' : 'itemOptionId';

      originalProjectItem.item = item;

      const formulaContext = {
        assortment,
        project,
        previousObj: originalProjectItem,
      };

      const projectItemChangesWithFormulaUpdates = await this.getProjectItemChangesFromFormulas(
        originalProjectItem,
        projectItemChanges,
        formulaContext,
      );
      delete originalProjectItem.item;

      const impactedPlaceholders = this.getMatchingPlaceholders(lookupKey, item.id);
      console.log('processProjectItemUpdatesAndGetChangesToApply: isOption: ', isOption, item);
      for (let j = 0; j < impactedPlaceholders.length; j++) {
        const ph = impactedPlaceholders[j];
        let phClone = ObjectUtil.cloneDeep(ph);
        if (processedPlaceholders[ph.id]) {
          phClone = processedPlaceholders[ph.id];
        } else {
          processedPlaceholders[ph.id] = phClone;
        }
        let originalProjectItem;
        if (isFamily) {
          const originalOptionProjectItem = phClone.itemOption?.projectItem
            ? ObjectUtil.cloneDeep(phClone.itemOption?.projectItem)
            : {};

          let currentItem: any = phClone.itemFamily;
          if (phClone.itemOption) {
            currentItem = phClone.itemOption;
          }
          originalProjectItem = ObjectUtil.cloneDeep(currentItem.projectItem) || {};
          currentItem.projectItem = currentItem.projectItem || {};
          let localChanges = this.determineChanges(item, originalProjectItem, projectItemChangesWithFormulaUpdates);
          if (Object.keys(localChanges).length > 0) {
            this.applyChange(currentItem.projectItem, localChanges);
          }
          this.applyChange(phClone?.itemFamily?.projectItem, projectItemChangesWithFormulaUpdates);

          const isPropagatingChangesToProjectItemOption = phClone.itemOption?.projectItem;
          if (isPropagatingChangesToProjectItemOption) {
            const itemOption = ObjectUtil.cloneDeep(phClone.itemOption);
            delete itemOption.projectItem;

            const optionProjectItem = phClone.itemOption.projectItem;
            optionProjectItem.item = itemOption;

            await EntityFormulaProcessor.processAndSetFormulaResultsOnEntity(optionProjectItem, {
              type: this.projectItemType,
              formulaContext: {
                assortment,
                project,
                previousObj: originalOptionProjectItem,
              },
              enableDebugLogs: false,
            });
          }

          // Get changes on option-level properties for itemFamily.
          // This is because option-level project-item props should be stored in placeholder instead of the itemFamily's project-item
          if (!currentItem.roles.includes('option')) {
            const phChanges = this.determinePhChanges(projectItemChangesWithFormulaUpdates);
            if (Object.keys(phChanges).length > 0) {
              this.applyChange(phClone, phChanges);
              phClones.push(phClone);
            }
          }
        }
        if (isOption) {
          // UPDATE THE PROJECT ITEM OBJECT, OR SET THE PROJECT ITEM
          phClone.itemOption.projectItem = phClone.itemOption.projectItem || {};
          originalProjectItem = ObjectUtil.cloneDeep(phClone.itemOption.projectItem) || {};
          let localChanges = this.determineChanges(item, originalProjectItem, projectItemChangesWithFormulaUpdates);
          this.applyChange(phClone.itemOption.projectItem, localChanges);
        }
        phClones.push(phClone);
      }
      if (!skipRecordingUndoRedo && originalProjectItem) {
        const undoChanges = {};
        Object.keys(projectItemChanges).forEach((att) => {
          undoChanges[att] = ObjectUtil.cloneDeep(originalProjectItem[att]);
        });
        undoRedoChanges.push({
          id: item.id,
          changes: projectItemChanges,
          undoChanges,
          scope: 'project-item',
          level: isOption ? 'option' : 'family',
        });
      }
    }

    const undoActionUuid = this.addUndoActionAndGetUuid(undoRedoChanges);
    const changes = await this.processPlaceholders(phClones, typeMap);
    return { placeholderChanges: changes, undoActionUuid };
  }

  public async getFamilyChangesToPropagate(values: any) {
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });

    const properties: string[] = projectItemType?.typeProperties
      ?.filter((property) => {
        return !['createdOn', 'updatedOn'].includes(property.slug);
      })
      .filter((property) => {
        return !property.propertyLevel || property.propertyLevel === 'family';
      })
      .map((property) => property.slug);
    const propertiesForPropagation = {};
    for (const property of Object.getOwnPropertyNames(values)) {
      if (properties.includes(property)) {
        propertiesForPropagation[property] = values[property];
      }
    }
    return propertiesForPropagation;
  }

  private async processPlaceholders(phClones, typeMap): Promise<Array<any>> {
    const assortment = this.getAssortment();
    const orgConfig = this.getOrgConfig();
    const changes: any[] = [];
    for (let i = 0; i < phClones.length; i++) {
      let phClone = phClones[i];
      phClone = await PlaceholderUtil.postProcessPlaceholder(phClone, assortment, orgConfig);
      phClone = Object.assign({}, phClone);
      changes.push({
        id: phClone.id,
        changes: phClone,
      });
    }

    return changes;
  }

  private addUndoActionAndGetUuid(changesList: any[]): string {
    if (changesList?.length) {
      const undoAction = this.undoRedoService.addUndo({
        actionType: UndoRedoActionType.UPDATE_PLACEHOLDER,
        changesList,
      });

      return undoAction?.uuid;
    }
  }

  private getTypeMap() {
    let typeMap;
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        take(1),
        tap((map) => {
          typeMap = map;
        }),
      )
      .subscribe();
    return typeMap;
  }

  private getMatchingPlaceholders(index, value) {
    let matches = [];
    this.store
      .select(CollectionManagerSelectors.selectCollectionData)
      .pipe(take(1))
      .subscribe((phs) => {
        matches = phs.filter((ph) => ph[index] === value);
      });
    return matches;
  }

  private applyChange(target, changes) {
    if (Object.keys(changes).length > 0 && target) {
      Object.assign(target, changes);
    }
  }

  private determineChanges(item, priorSourceValues, newSourceValues) {
    const target = item.projectItem || {};
    let changes: any = {};
    for (const prop of this.projectItemType.typeProperties) {
      const propKey = prop.slug;
      if (!prop) {
        continue;
      }
      if (newSourceValues[propKey] === undefined) {
        continue;
      }
      if (prop.propertyLevel === 'overridable') {
        const overridableChanges = ObjectUtil.determineChangesIfEqual(target, priorSourceValues, newSourceValues, [
          prop,
        ]);
        changes = { ...changes, ...overridableChanges };
      } else {
        if (!item.roles.includes('option') && prop.propertyLevel === 'option') {
          continue;
        }
        changes[propKey] = newSourceValues[propKey];
        if (newSourceValues.hasOwnProperty(propKey + 'Id')) {
          changes[propKey + 'Id'] = newSourceValues[propKey + 'Id'];
        }
      }
    }
    return changes;
  }

  private determinePhChanges(newSourceValues) {
    const changes: any = {};
    for (const prop of this.projectItemType.typeProperties) {
      const propKey = prop.slug;
      if (!prop) {
        continue;
      }
      if (newSourceValues[propKey] === undefined) {
        continue;
      }
      if (prop.propertyLevel === 'option') {
        changes[propKey] = newSourceValues[propKey];
        if (newSourceValues.hasOwnProperty(propKey + 'Id')) {
          changes[propKey + 'Id'] = newSourceValues[propKey + 'Id'];
        }
      }
    }
    return changes;
  }

  private getAssortment() {
    let plan;
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((currentPlan) => {
          plan = currentPlan;
        }),
      )
      .subscribe();
    return plan.targetAssortment;
  }

  private getProject(): Project {
    let project;
    this.store
      .select(WorkspacesSelectors.currentProject)
      .pipe(
        take(1),
        tap((currentProject) => {
          project = currentProject;
        }),
      )
      .subscribe();

    return project;
  }

  private getOrgConfig() {
    let org;
    this.store
      .select(AuthSelectors.currentOrg)
      .pipe(
        take(1),
        tap((currentOrg) => {
          org = currentOrg;
        }),
      )
      .subscribe();
    return org.orgConfig;
  }

  private async getProjectItemChangesFromFormulas(
    originalProjectItem: any,
    projectItemUpdates: any,
    formulaContext: { assortment: any; previousObj: any; project: Project },
  ): Promise<any> {
    const projectItemType = await new Types().getType({ id: originalProjectItem.typeId });
    const updatedProjectItem = Object.assign({}, originalProjectItem, projectItemUpdates);
    const projectItemBeforeFormulaProcessing = ObjectUtil.cloneDeep(updatedProjectItem);

    await EntityFormulaProcessor.processAndSetFormulaResultsOnEntity(updatedProjectItem, {
      type: projectItemType,
      formulaContext,
      enableDebugLogs: false,
    });

    const projectItemProperties = projectItemType.typeProperties;
    const projectItemChangesWithFormulaUpdates = {};
    for (const property of projectItemProperties) {
      const updatedProjectItemValue = updatedProjectItem[property.slug];
      const originalProjectItemValue = originalProjectItem[property.slug];
      const preFormulaProjectItemValue = projectItemBeforeFormulaProcessing[property.slug];

      if (this.isDifferentDeepCompare(updatedProjectItemValue, originalProjectItemValue)) {
        projectItemChangesWithFormulaUpdates[property.slug] = updatedProjectItemValue;
      }

      if (this.isDifferentDeepCompare(updatedProjectItemValue, preFormulaProjectItemValue)) {
        projectItemChangesWithFormulaUpdates[property.slug] = updatedProjectItemValue;
      }
    }

    return projectItemChangesWithFormulaUpdates;
  }

  private isDifferentDeepCompare(value1, value2) {
    if ((!value1 && value2) || (value1 && !value2)) {
      return true;
    }

    if (typeof value1 !== typeof value2) {
      return true;
    }

    if (['string', 'number'].includes(typeof value1) && value1 !== value2) {
      return true;
    }

    const objectDiffs = ObjectUtil.compareDeep(value1, value2, '');
    if (objectDiffs.length > 0) {
      return true;
    }

    return false;
  }
}
