<app-object-reference-chip
  [edit]="true"
  [object]="value"
  [typeProperty]="property?.propertyDefinition"
  [objectProperties]="itemProperties"
  [allowDisplayTextShortening]="true"
  *ngIf="value?.id"
  (clearValue)="clearValue()"
  (clicked)="handleClick()"
>
</app-object-reference-chip>

<!-- Stop propagation of the mouse events so that they don't get to the cell level-->
<div
  class="empty-state"
  *ngIf="!value?.id && hideChooser"
  (click)="handleAddClick(); $event.stopPropagation()"
  (mousedown)="$event.stopPropagation()"
  (mouseenter)="$event.stopPropagation()"
  (mouseup)="$event.stopPropagation()"
>
  <div class="add-button"><mat-icon>add</mat-icon></div>
</div>

<div #chooser [style.top.px]="panelTopPosition" class="panel" *ngIf="!value?.id && !hideChooser">
  <div *ngIf="!create">
    <app-plan-item-chooser
      [criteria]="baseCriteria"
      resultsHeight="300px"
      [showHeader]="false"
      [showFilter]="false"
      (entityClicked)="select($event)"
      (close)="handleClose()"
      (click)="$event.stopPropagation()"
      (mousedown)="$event.stopPropagation()"
      (mouseenter)="$event.stopPropagation()"
      (mouseup)="$event.stopPropagation()"
      [allowShowDetails]="false"
      [ignorePersistedBaseCriteria]="true"
    >
    </app-plan-item-chooser>
    <div class="actions mt-2" *ngIf="isCreateAllowed">
      <button mat-flat-button color="primary" (click)="new()">New</button>
    </div>
  </div>
  <div *ngIf="create">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input id="nameInput" [formControl]="nameFormField" matInput />
    </mat-form-field>
    <div class="actions mt-2">
      <button mat-flat-button color="primary" [disabled]="nameFormField.invalid" (click)="createNewObject()">
        Save
      </button>
    </div>
  </div>
</div>
