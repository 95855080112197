import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  public async getProjectById(id: string) {
    return new Entities().get({ entityName: 'project', id });
  }
}
