import { ReplaySubject } from 'rxjs';
import { buffer, debounceTime } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { API_VERSION, Entities, SortOrderOptions } from '@contrail/sdk';
import { AssortmentHistoryService } from '@common/assortment-history/assortment-history.service';
import { PaginatedSearchResult } from '@common/entities/entities.interfaces';
import { EntitiesHelper } from '@common/entities/entities-helper';
import { EntitySnapshot } from './document-history-store/document-history.state';

const DEBOUNCE_TIME = 3000;

@Injectable({
  providedIn: 'root',
})
export class EntitySnapshotService {
  private createSnapshotSubject: ReplaySubject<string> = new ReplaySubject(1);

  constructor(private assortmentHistoryService: AssortmentHistoryService) {
    this.debounceCreateEntitySnapshot();
  }

  public async getEntitySnapshotById(id: string) {
    const snapshot = await new Entities().get({ entityName: 'entity-snapshot', id });
    if (snapshot.snapshotDownloadURL) {
      const response = await fetch(snapshot.snapshotDownloadURL);
      const snapshotDetails = await response.json();
      snapshot.snapshot = snapshotDetails;
    }
    return snapshot;
  }

  public async getAllEntitySnapshots(options: { criteria: { [key: string]: any }; relations?: string[] }) {
    const { criteria, relations } = options;

    const snapshots = await EntitiesHelper.getAllPaginatedResults<EntitySnapshot>((nextPageKey) =>
      this.getEntitySnapshotsPaginated({ criteria, relations, nextPageKey }),
    );

    return snapshots;
  }

  public async getEntitySnapshotsPaginated(options: {
    criteria: { [key: string]: any };
    relations?: string[];
    nextPageKey?: string;
  }): Promise<PaginatedSearchResult<EntitySnapshot>> {
    const { criteria, relations, nextPageKey } = options;

    const results: PaginatedSearchResult<EntitySnapshot> = await new Entities().get({
      entityName: 'entity-snapshot',
      criteria,
      relations,
      order: [{ order: SortOrderOptions.DESC, orderField: 'createdOn' }],
      take: 3000,
      apiVersion: API_VERSION.V2,
      paginate: true,
      nextPageKey,
    });

    return results;
  }

  public async setAssortmentHistory(documentHistoryObjs: any[], assortmentId: string) {
    const assortmentHistory = await this.assortmentHistoryService.getAssortmentHistoryItems(assortmentId);

    documentHistoryObjs.forEach((historyObj) => {
      if (historyObj.assortmentChangeId) {
        const assortmentHistoryObj = assortmentHistory.find(
          (assortmentHistoryObj) => assortmentHistoryObj.id === historyObj.assortmentChangeId,
        );
        historyObj.publishName = assortmentHistoryObj.versionName;
      }
    });
  }

  public async createEntitySnapshot(entityReference: string) {
    return new Entities().create({ entityName: 'entity-snapshot', object: { entityReference } });
  }

  public async createEntitySnapshotWithDebounce(entityReference: string) {
    this.createSnapshotSubject.next(entityReference);
  }

  private async combineCreateEntitySnapshotRequests(entityReferences: Array<string>) {
    const uniqueEntityReferences = Array.from(new Set(entityReferences));
    for (const entityReference of uniqueEntityReferences) {
      await this.createEntitySnapshot(entityReference);
    }
  }

  private async debounceCreateEntitySnapshot() {
    // Observable that only emits a value if no additional value is added within DEBOUNCE_TIME
    const debounceAndCreateSnapshot$ = this.createSnapshotSubject.pipe(debounceTime(DEBOUNCE_TIME));

    // Observable which buffers any emitted values until debounceAndCreateSnapshot$ emits a value and closes the buffer
    const createSnapshot$ = this.createSnapshotSubject.pipe(buffer(debounceAndCreateSnapshot$));

    createSnapshot$.subscribe((entityReferences) => {
      this.combineCreateEntitySnapshotRequests(entityReferences);
    });
  }
}
