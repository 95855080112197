<app-object-reference-chip
  *ngIf="value?.id"
  [edit]="true"
  [object]="value"
  [typeProperty]="property?.propertyDefinition"
  [allowDisplayTextShortening]="true"
  (clearValue)="clearValue()"
  (clicked)="handleClick()"
>
</app-object-reference-chip>

<div
  *ngIf="!value?.id && hideChooser"
  class="empty-state"
  (click)="handleAddItemClick(); $event.stopPropagation()"
  (mousedown)="$event.stopPropagation()"
  (mouseenter)="$event.stopPropagation()"
  (mouseup)="$event.stopPropagation()"
>
  <div class="add-button"><mat-icon>add</mat-icon></div>
</div>

<div #chooser *ngIf="!value?.id && !hideChooser" [style.top.px]="panelTopPosition" class="panel">
  <div *ngIf="!createMode">
    <app-object-reference-chooser
      (entitySelected)="handleEntitySelected($event)"
      [typeProperty]="property.propertyDefinition"
      (close)="handleClose()"
      [context]="isItemReference ? null : data"
      [enableInfiniteScroll]="isInfiniteScrollEnabled"
    ></app-object-reference-chooser>
    <div class="actions mt-2" *ngIf="canCreate">
      <button data-test="create-entity-button" mat-flat-button color="primary" (click)="new()">New</button>
    </div>
  </div>
  <div [ngStyle]="{ display: !createMode ? 'none' : 'block' }">
    <app-create-entity
      [entityType]="property.propertyDefinition.referencedTypePath"
      (createComplete)="handleCreateComplete($event)"
    >
    </app-create-entity>
  </div>
</div>
