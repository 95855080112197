import { State } from './workspaces.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as WorkspacesActions from './workspaces.actions';
import { workspacesEntityAdapter } from './workspaces.state';
import { workspacesInitialState } from './workspaces.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.setAll(data, state.workspaces),
    workspacesLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.removeAll(state.workspaces),
    workspacesLoaded: false,
  };
};
const add = (state: State, { workspace }) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.addOne(workspace, state.workspaces),
  };
};
const update = (state: State, { id, changes }) => {
  // refresh the current workspace is current
  let currentWorkspace = state.currentWorkspace;
  if (state.currentWorkspace?.id === id) {
    currentWorkspace = { ...state.currentWorkspace, ...changes };
  }
  return {
    ...state,
    workspaces: workspacesEntityAdapter.updateOne({ id, changes }, state.workspaces),
    currentWorkspace,
  };
};
const remove = (state: State, { workspace }) => {
  return {
    ...state,
    workspaces: workspacesEntityAdapter.removeOne(workspace.id, state.workspaces),
  };
};

const setCurrentWorkspace = (state: State, { workspace }) => {
  return {
    ...state,
    currentWorkspace: workspace,
  };
};
const setCurrentWorkspaceFolder = (state: State, { workspace }) => {
  return {
    ...state,
    currentWorkspaceFolder: workspace,
  };
};

// Workspace entities
const removeManyWorkspaceFolderEntities = (state: State, { workspaceEntityIds }) => {
  let workspaceEntities = [...state.workspaceFolderEntities];
  workspaceEntities = workspaceEntities.filter((we) => !workspaceEntityIds.includes(we.id));
  return {
    ...state,
    workspaceFolderEntities: workspaceEntities,
  };
};

const setWorkspaceFolderEntities = (state: State, { workspaceEntities }) => {
  return {
    ...state,
    workspaceFolderEntities: workspaceEntities,
  };
};

const setCurrentWorkspaceFolderLoading = (state: State, { loading }) => {
  return {
    ...state,
    currentWorkspaceFolderLoading: loading,
  };
};

const setWorkspaceFolderStructure = (state: State, { workspaceFolderStructure }) => {
  return {
    ...state,
    workspaceFolderStructure,
  };
};

const setCurrentProject = (state: State, { project }) => {
  return {
    ...state,
    currentProject: project,
  };
};

const removeFolderEntitiesByReference = (state: State, { entityReferences }) => {
  const ids = state.workspaceFolderEntities
    .filter((we) => entityReferences.includes(we.entityReference))
    .map((we) => we.id);

  let workspaceEntities = [...state.workspaceFolderEntities];
  workspaceEntities = workspaceEntities.filter((we) => !ids.includes(we.id));
  return {
    ...state,
    workspaceFolderEntities: workspaceEntities,
  };
};

export const workspacesReducers = createReducer(
  workspacesInitialState,
  on(WorkspacesActions.loadWorkspacesSuccess, setData),
  on(WorkspacesActions.clearWorkspaces, clearData),
  on(WorkspacesActions.createWorkspaceSuccess, add),
  on(WorkspacesActions.deleteWorkspaceSuccess, remove),
  on(WorkspacesActions.updateWorkspaceSuccess, update),
  on(WorkspacesActions.loadCurrentWorkspaceSuccess, setCurrentWorkspace),
  on(WorkspacesActions.loadCurrentWorkspaceProjectSuccess, setCurrentProject),

  // Workspace entities
  on(WorkspacesActions.setCurrentWorkspaceFolder, setCurrentWorkspaceFolder),
  on(WorkspacesActions.loadWorkspaceFolderEntitiesSuccess, setWorkspaceFolderEntities),
  on(WorkspacesActions.setCurrentWorkspaceFolderLoading, setCurrentWorkspaceFolderLoading),
  on(WorkspacesActions.moveFolderEntitiesSuccess, removeManyWorkspaceFolderEntities),
  on(WorkspacesActions.removeFolderEntities, removeFolderEntitiesByReference),

  // Workspace Folder Structure
  on(WorkspacesActions.setWorkspaceFolderStructure, setWorkspaceFolderStructure),
);

export function reducer(state: State | undefined, action: Action): any {
  return workspacesReducers(state, action);
}
