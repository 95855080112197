import { AfterViewInit, Component, OnChanges, SimpleChanges } from '@angular/core';
import { SubTypesHelper, Types } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { PlansSelectors, RootStoreState } from '@rootstore';
import { Subject, BehaviorSubject } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { DropDownOption } from '../components/drop-down-list.component';
import { DataCellEditBaseComponent } from '../data-cell-edit-base-component';
@Component({
  selector: 'app-data-cell-edit-type-reference',
  template: `<app-drop-down-list
    (optionChange)="handleTypeChange($event)"
    [selectedOption]="selectedOption$ | async"
    [dropDownOptions]="typeOptions$ | async"
  >
  </app-drop-down-list>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      input {
        justify-content: flex-end;
        text-align: right;
        border: none;
        font-size: 12px;
        line-height: normal;
        width: 95%;
      }
    `,
  ],
})
export class DataCellEditTypeReferenceComponent extends DataCellEditBaseComponent implements AfterViewInit, OnChanges {
  public selectedOption$: Subject<DropDownOption> = new Subject();
  public typeOptions$: Subject<Array<DropDownOption>> = new BehaviorSubject(null);
  public typeList: Array<any>;
  baseType: Type;
  constructor(private store: Store<RootStoreState.State>) {
    super();
  }

  async ngAfterViewInit() {
    let currentAssortment;
    this.store
      .select(PlansSelectors.currentPlan)
      .pipe(
        take(1),
        tap((plan) => {
          if (plan) {
            currentAssortment = plan.targetAssortment;
          }
        }),
      )
      .subscribe();

    if (this.property.slug === 'itemType' && currentAssortment?.itemTypeId) {
      this.baseType = await new Types().getType({ id: currentAssortment.itemTypeId });
    } else {
      this.baseType = await new Types().getType({ path: this.property.propertyDefinition.referencedTypeRootSlug });
    }

    if (this.value) {
      const selectedType = await new Types().getType({ id: this.value });
      this.selectedOption$.next({
        display: selectedType.label,
        value: selectedType.id,
      });
    }

    const path = this.baseType ? this.baseType.typePath : this.property.propertyDefinition?.referencedTypePath;
    if (path) {
      this.typeOptions$.next(await this.getTypeOptions(path));
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const wasTypeChanged = Boolean(changes.value && changes.value.currentValue);
    if (!wasTypeChanged) {
      return;
    }

    const selectedType = await new Types().getType({ id: changes.value.currentValue });
    this.selectedOption$.next({
      display: selectedType?.label,
      value: selectedType?.id,
    });
  }

  async handleTypeChange(event) {
    this.value = event.value;
    this.handleChange();
  }

  async getTypeOptions(path): Promise<Array<DropDownOption>> {
    const typesList = await SubTypesHelper.getTypeListFromPath(path);
    const options = typesList.map((type) => {
      return {
        display: type.label,
        longDisplay: type.pathLabel,
        value: type.id,
      };
    });
    return options;
  }
}
